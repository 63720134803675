import React, {Component} from 'react';
import {ROW_TYPE} from './Schema';
import PropTypes from 'prop-types';
import TextRow from './TextRow';
import AddRemoveButtons from './AddRemoveButtons';
import MoneyRow from '../estimates/MoneyRow';
import CheckBoxRow from './CheckBoxRow';
import CustomerOptionRow from '../estimates/CustomerOptionRow';
import DateFieldRow from '../estimates/DateFieldRow';

export default class FormRow extends Component {
    renderRowType = () => {
        const {onChange, quickbooksClasses, quickbooksServices, row} = this.props;

        switch (row.type) {
        case ROW_TYPE.DATE_FIELD:
            return <DateFieldRow onChange={onChange} row={row}/>;
        case ROW_TYPE.DATETIME_FIELD:
            return <DateFieldRow onChange={onChange} row={row} withTime/>;
        case ROW_TYPE.CUSTOMER_OPTION:
            return <CustomerOptionRow onChange={onChange} row={row}/>;
        case ROW_TYPE.SERVICE_WITH_TEXT:
            return <CheckBoxRow onChange={onChange} row={row}/>;
        case ROW_TYPE.SERVICE:
            return <CheckBoxRow onChange={onChange} row={row}/>;
        case ROW_TYPE.MONEY_OPTIONAL:
            return <MoneyRow onChange={onChange} quickbooksClasses={quickbooksClasses}
                quickbooksServices={quickbooksServices} row={row}/>;
        case ROW_TYPE.TEXT_BLOCK:
            return <TextRow onChange={onChange} row={row}/>;
        default:
            return null;
        }
    };

    render() {
        const {copySelf, destroySelf, parentList, selfIndex} = this.props;

        return (
            <div className='row'>
                <div className='grid-x align-middle'>
                    <div className='shrink cell'>
                        <AddRemoveButtons insert={() => copySelf(parentList, selfIndex)}
                            remove={() => destroySelf(parentList, selfIndex)}/>
                    </div>
                    <div className='auto cell'>
                        {this.renderRowType()}
                    </div>
                </div>
            </div>
        );
    }
}

FormRow.propTypes = {
    copySelf: PropTypes.func.isRequired,
    destroySelf: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    parentList: PropTypes.array.isRequired,
    quickbooksClasses: PropTypes.array,
    quickbooksServices: PropTypes.array,
    row: PropTypes.object.isRequired,
    selfIndex: PropTypes.number.isRequired
};