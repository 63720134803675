import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import UserWidget from './UserWidget';

const UsersTableHeader = () =>
    <div className='curved primary thead'>
        <div className='grid-x grid-margin-x align-middle'>
            <div className='auto cell'>
                <small>User Info</small>
            </div>
            <div className='medium-3 cell'>
                <small>Roles</small>
            </div>
            <div className='medium-2 cell'>
                <small>Create Date</small>
            </div>
            <div className='medium-2 cell'>
                <small>Last Sign In Date</small>
            </div>
        </div>
    </div>;

const UsersTableRow = ({user}) =>
    <a
        className='tr'
        href={`/users/${user.id}`}
    >
        <div className='grid-x grid-margin-x align-middle align-justify'>
            <div className='auto cell text-truncate tight'>
                <UserWidget user={user} withEmail withLabel/>
            </div>
            <div className='shrink medium-3 cell'>
                {user.roles.map(role => <span className='success label' key={role}>{role}</span>)}
            </div>
            <div className='medium-2 cell show-for-medium'>
                <div className='text-truncate'>{moment(user.createdAt).format('ll')}</div>
            </div>
            <div className='medium-2 cell show-for-medium'>
                <div className='text-truncate'>
                    {user.lastSignInAt && moment(user.lastSignInAt).format('ll')}
                </div>
            </div>
        </div>
    </a>;

UsersTableRow.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        lastSignInAt: PropTypes.string,
        roles: PropTypes.array.isRequired
    })
};

const UsersTable = ({users}) =>
    <div className='card'>
        <div className='curved table'>
            {users.length > 0 && <UsersTableHeader/>}
            {users.map(user =>
                <UsersTableRow
                    key={user.id}
                    user={user}
                />)}
        </div>
    </div>;

UsersTable.propTypes = {
    users: PropTypes.array.isRequired
};

export default UsersTable;