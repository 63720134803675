import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {Map, InfoWindow, AdvancedMarkerElement, GoogleApiWrapper} from 'google-maps-react';
import AssignJobButton from '../dialog_buttons/AssignJobButton';
import Address from '../Address';
import BlueMapMarker from '../../assets/images/map-marker-blue.png';
import RedMapMarker from '../../assets/images/map-marker-red.png';

const mapStyles = {
    width: '100%',
    height: '100%',
    position: 'relative'
};

class InfoWindowEx extends PureComponent {
    constructor(props) {
        super(props);
        this.infoWindowRef = React.createRef();
        this.contentElement = document.createElement('div');
    }

    componentDidUpdate(prevProps) {
        if (this.props.children !== prevProps.children) {
            ReactDOM.render(React.Children.only(this.props.children),
                this.contentElement);
            this.infoWindowRef.current.infowindow.setContent(this.contentElement);
        }
    }

    render() {
        return <InfoWindow ref={this.infoWindowRef} {...this.props}/>;
    }
}

InfoWindowEx.propTypes = {
    children: PropTypes.any
};

export class JobsMap extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedJob: {}
        };
        this.bounds = new props.google.maps.LatLngBounds();
        this.mapRef = React.createRef();
    }

    componentDidMount() {
        const top = document.getElementById('jobs-map').offsetTop;
        const height = window.innerHeight;
        document.getElementById('jobs-map').style.height = `${height - top - 50}px`;
    }

    adjustMap = () => {
        if (this.mapRef.current) {
            this.mapRef.current.map.fitBounds(this.bounds);
        }
    };

    onMarkerClick = (props, marker) =>
        this.setState({
            selectedJob: props.job,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMapClicked = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    getBounds = () => {
        this.bounds = new this.props.google.maps.LatLngBounds();

        for (const job of this.props.jobs) {
            this.bounds.extend({
                lat: job.address.latitude || 49.8925793,
                lng: job.address.longitude || -97.1478263
            });
        }

        return this.bounds;
    };

    displayMarkers = () => this.props.jobs.map(job =>
        <AdvancedMarkerElement icon={{
            url: job.assignee ? BlueMapMarker : RedMapMarker
        }} job={job} key={job.id} onClick={this.onMarkerClick} position={{
            lat: job.address.latitude || 49.8925793,
            lng: job.address.longitude || -97.1478263
        }}/>);

    render() {
        const {activeMarker, showingInfoWindow, selectedJob} = this.state;
        const {editable, google} = this.props;
        return (
            <div className='radius margin-bottom-1' id='jobs-map'>
                <Map
                    bounds={this.getBounds()}
                    containerStyle={mapStyles}
                    google={google}
                    initialCenter={{lat: 49.8925793, lng: -97.1478263}}
                    onClick={this.onMapClicked}
                    onReady={this.adjustMap}
                    ref={this.mapRef}
                    style={mapStyles}
                >
                    {this.displayMarkers()}
                    <InfoWindowEx
                        marker={activeMarker}
                        visible={showingInfoWindow}>
                        {showingInfoWindow ? <div className='margin-0'>
                            <div><b>{selectedJob.customer.fullName}</b></div>
                            <Address address={selectedJob.address}/><br/>
                            <b>Assigned to:</b>
                            <AssignJobButton editable={editable} job={selectedJob} widget/>
                        </div> : <div/>}
                    </InfoWindowEx>
                </Map>
            </div>
        );
    }
}

JobsMap.propTypes = {
    editable: PropTypes.bool.isRequired,
    google: PropTypes.object.isRequired,
    jobs: PropTypes.array.isRequired
};

// eslint-disable-next-line new-cap
export default GoogleApiWrapper(props => props)(JobsMap);