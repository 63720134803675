import React, {PureComponent} from 'react';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import moment from 'moment';
import PropTypes from 'prop-types';
import {notify} from 'react-notify-toast';
import Cookies from 'js-cookie';
import Rails from '@rails/ujs';

const SECONDS_BEFORE_NOTIFY = 5 * 60;

/* global Turbolinks:true */
export default class TimeoutDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.timeOutCountDownStartTime = new Date();
        Cookies.set('timeOutCountDownStartTime', this.timeOutCountDownStartTime.toISOString());
        this.timer = null;
        this.state = {
            secondsElapsed: 0,
            visible: false
        };
        this.title = document.title;
    }

    componentDidMount() {
        const {secondsBeforeTimeout} = this.props;

        this.dialog = new Foundation.Reveal($('#timeout-dialog'));

        this.timer = setInterval(() => {
            const {visible} = this.state;
            const startTime = moment(Cookies.get('timeOutCountDownStartTime') || this.timeOutCountDownStartTime);
            const secondsElapsed = Math.round(moment.duration(moment().diff(startTime)).asSeconds());

            if (!visible && secondsBeforeTimeout - secondsElapsed <= SECONDS_BEFORE_NOTIFY + 1) {
                this.setState({visible: true}, () => {
                    this.dialog.open();
                });
            }
            else if (secondsElapsed - secondsBeforeTimeout >= 0) {
                this.logout();
            }

            this.setState({secondsElapsed});
        }, 250);
    }

    componentDidUpdate() {
        const {secondsBeforeTimeout} = this.props;
        const {secondsElapsed} = this.state;
        const secondsLeft = Math.max(secondsBeforeTimeout - secondsElapsed, 0);

        if (secondsLeft <= SECONDS_BEFORE_NOTIFY) {
            document.title = `${secondsLeft} seconds to timeout!`;
        }
    }

    componentWillUnmount() {
        if (this.dialog) {
            this.dialog.destroy();
        }

        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    logout = () => {
        const {redirectUrl, sessionDestroyUrl} = this.props;
        Rails.ajax({
            url: sessionDestroyUrl,
            type: 'DELETE',
            success: () => {
                this.dialog.destroy();
                this.dialog = null;
                clearInterval(this.timer);
                Cookies.set('timeOutCountDownStartTime', new Date(0).toISOString());
                Turbolinks.visit(redirectUrl);
            },
            error: () => {
                notify.show('Sorry, but we could not log you out.', 'error');
            }
        });
    };

    continue = () => {
        Rails.ajax({
            url: location.href,
            type: 'GET',
            success: () => {
                this.dialog.close();
                this.timeOutCountDownStartTime = new Date();
                Cookies.set('timeOutCountDownStartTime', new Date().toISOString());
                this.setState({secondsElapsed: 0, visible: false});
                document.title = this.title;
            },
            error: () => {
                notify.show('Sorry, but we could not continue your session.', 'error');
            }
        });
    };

    render() {
        const {secondsBeforeTimeout} = this.props;
        const {secondsElapsed} = this.state;
        const secondsLeft = Math.max(secondsBeforeTimeout - secondsElapsed, 0);
        return (
            <div>
                <div className='reveal text-center'
                    data-close-on-click={false}
                    data-close-on-esc={false}
                    id='timeout-dialog'>
                    <p className='h1'><b>Session Timeout</b></p>
                    <p className='lead'>You will be automatically logged out in:</p>
                    <p className='h1 margin-bottom-2'>{secondsLeft} seconds</p>
                    <div className='grid-x grid-margin-x align-center'>
                        <div className='shrink cell'>
                            <a className='large alert margin-0 button' onClick={this.logout}>Log Out</a>
                        </div>
                        <div className='shrink cell'>
                            <a className='large success margin-0 button' onClick={this.continue}>Continue</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TimeoutDialog.propTypes = {
    redirectUrl: PropTypes.string.isRequired,
    secondsBeforeTimeout: PropTypes.number.isRequired,
    sessionDestroyUrl: PropTypes.string.isRequired
};