import React from 'react';
import PropTypes from 'prop-types';

const UserLabel = ({user}) => {
    if (user['me?']) {
        return <span className='warning label'>you</span>;
    }
    else if (!user['active?']) {
        return <span className='secondary label'>disabled</span>;
    }
    else if (user['invitedToSignUp?']) {
        return <span className='primary label'>invited</span>;
    }
    else if (!user['confirmed?']) {
        return <span className='warning label'>unconfirmed</span>;
    }
    else if (user['accessLocked?']) {
        return <span className='alert label'>locked</span>;
    }

    return null;
};

UserLabel.propTypes = {
    user: PropTypes.object.isRequired
};

export default UserLabel;