import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Address from '../Address';
import JobUrgency from '../jobs/JobUrgency';
import AssignWorkOrderButton from '../dialog_buttons/AssignWorkOrderButton';

const WorkOrdersTableHeader = () =>
    <div className='curved primary thead'>
        <div className='grid-x grid-margin-x align-middle'>
            <div className='medium-1 cell text-center'>
                <small>Assigned</small>
            </div>
            <div className='medium-3 cell'>
                <small>Customer</small>
            </div>
            <div className='medium-3 cell'>
                <small>Address</small>
            </div>
            <div className='auto cell text-center'>
                <small>Urgency</small>
            </div>
            <div className='auto cell text-center'>
                <small>Status</small>
            </div>
            <div className='auto cell text-center'>
                <small>Start Date</small>
            </div>
        </div>
    </div>;

/* global Turbolinks:true */
const WorkOrderTableRow = ({editable, workOrder}) =>
    <div className={`tr ${workOrder.urgency ? workOrder.urgency.toLowerCase() : ''}`} onClick={() =>
        Turbolinks.visit(`/work-orders/${workOrder.id}`)}>
        <div className='grid-x grid-margin-x align-middle align-justify'>
            <div className='small-4 medium-1 cell text-center small-order-6 medium-order-1'
                onClick={e => e.stopPropagation()}>
                <AssignWorkOrderButton editable={editable} workOrder={workOrder}/>
            </div>
            <div className='small-6 medium-3 cell small-order-1 medium-order-2 text-truncate'>
                <div className='text-truncate'><b>{workOrder.customer.fullName}</b></div>
                <small>{workOrder.services.join(', ')}</small>
            </div>
            <div className='small-6 medium-3 cell small-order-2'>
                <Address address={workOrder.address}/>
            </div>
            <div className='small-4 medium-auto cell text-center small-order-3 show-for-medium'>
                {workOrder.urgency && <JobUrgency urgency={workOrder.urgency}/>}
            </div>
            <div className='small-4 medium-auto cell text-center small-order-5'>
                <div className='text-truncate'><b><span className='info label'>{workOrder.status}</span></b></div>
            </div>
            <div className='small-4 medium-auto cell medium-text-center small-order-4 medium-order-6'>
                <div className='text-truncate'>{moment(workOrder.startDate).format('D MMM YYYY')}</div>
            </div>
        </div>
    </div>;

WorkOrderTableRow.propTypes = {
    editable: PropTypes.bool.isRequired,
    workOrder: PropTypes.shape({
        address: PropTypes.object.isRequired,
        customer: PropTypes.shape({
            fullName: PropTypes.string.isRequired
        }),
        id: PropTypes.number.isRequired,
        startDate: PropTypes.string.isRequired,
        services: PropTypes.arrayOf(PropTypes.string).isRequired,
        status: PropTypes.string.isRequired,
        urgency: PropTypes.string
    }).isRequired
};

const WorkOrdersTable = ({editable, workOrders}) =>
    <div className='card'>
        <div className='curved table'>
            {workOrders.length > 0 && <WorkOrdersTableHeader/>}
            {workOrders.map(workOrder =>
                <WorkOrderTableRow editable={editable} key={workOrder.id} workOrder={workOrder}/>)}
        </div>
    </div>;

WorkOrdersTable.propTypes = {
    editable: PropTypes.bool.isRequired,
    workOrders: PropTypes.array.isRequired
};

export default WorkOrdersTable;