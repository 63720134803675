import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({className, maxLength, onChange, placeholder, value}) =>
    <input className={className} maxLength={maxLength} onChange={e => onChange(e.target.value)}
        placeholder={placeholder} type='text' value={value}/>;

InputField.propTypes = {
    className: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string
};

export default InputField;