import React, {PureComponent} from 'react';
import {AlertCallout} from './utilities/Callout';
import FAIcon from './utilities/FAIcon';
import Spinner from './utilities/Spinner';
import Cookies from 'js-cookie';
import StripeTerminal from './StripeTerminal';

export default class CardReaderManager extends PureComponent {
    constructor(props) {
        super(props);

        this.dialog = null;
        this.clientSecret = null;
        this.state = {
            activeReaderId: Cookies.get('activeReaderId'),
            connected: false,
            error: null,
            pendingReader: null,
            stripe: {}
        };
    }

    componentDidMount() {
        this.initialize();
    }

    initialize = () => {
        StripeTerminal.create().then(stripe => {
            this.setState({stripe});
            const reader = stripe.readers.find(r => r.id === this.state.activeReaderId);

            if (reader && reader.status === 'online') {
                this.connectReader(reader);
            }
        }).catch(error => {
            this.setState({error: error.message});
        });
    };

    connectReader = reader => {
        const {stripe} = this.state;
        this.setState({pendingReader: reader, error: null});
        stripe.instance.connectReader(reader).then(connectResult => {
            this.setState({pendingReader: null, error: null});

            if (connectResult.error) {
                this.setState({connected: false});
                this.setState({error: `Failed to connect: ${connectResult.error.message}`});
            }
            else {
                Cookies.set('activeReaderId', reader.id);
                this.setState({activeReaderId: reader.id, connected: true});
            }
        });
    };

    disconnectReader = () => {
        const {activeReaderId, stripe} = this.state;
        const reader = stripe.readers.find(r => r.id === activeReaderId);
        stripe.instance.disconnectReader(reader).then(result => {
            if (result.error) {
                this.setState({error: result.error.message});
            }
            else {
                Cookies.remove('activeReaderId');
                this.setState({activeReaderId: null, connected: null});
            }
        });
    };

    renderConnectionStatus = reader => {
        const {activeReaderId, connected, pendingReader} = this.state;

        if (pendingReader === reader) {
            return <a className='clear margin-0 button'>
                <FAIcon icon='spinner fa-spin' text='Connecting...'/>
            </a>;
        }
        else if (activeReaderId === reader.id && connected) {
            return <a className='clear margin-0 success button' onClick={this.disconnectReader}>
                <FAIcon icon='check' text='Connected'/>
            </a>;
        }

        return <button
            className='clear margin-0 button'
            disabled={reader.status !== 'online'}
            onClick={() => this.connectReader(reader)}
            type='button'>
            <FAIcon icon='plug' text='Connect'/>
        </button>;
    };

    renderCardReader = (reader, index) => {
        const {activeReaderId} = this.state;
        const icon = reader.status === 'online' ? 'wifi' : 'warning';

        const className = () => {
            if (reader.status === 'online') {
                if (reader.id === activeReaderId) {
                    return 'success-color';
                }

                return 'primary-color';
            }

            return 'alert-color';
        };

        return (
            <React.Fragment key={reader.id}>
                {index > 0 && <hr className='vertically-padded'/>}
                <div className='grid-x grid-margin-x align-middle tight'>
                    <div className='auto cell'>
                        <div>{reader.label}</div>
                        <small>
                            <pre>Serial: {reader.serial_number}</pre>
                        </small>
                    </div>
                    <div className='shrink large-auto cell text-center'>
                        <FAIcon className={`${className()} fa-2x fa-fw`} icon={icon} title={reader.status}/>
                    </div>
                    <div className='small-5 medium-4 large-4 cell text-right'>
                        {this.renderConnectionStatus(reader)}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    renderActiveReaderConnectionStatus = () => {
        const {activeReaderId, stripe: {readers}} = this.state;

        if (activeReaderId && readers) {
            const reader = readers.find(r => r.id === activeReaderId);
            const icon = reader.status === 'online' ? 'wifi' : 'warning';
            const className = reader.status === 'online' ? 'success-color' : 'alert-color';
            return <FAIcon className={className} icon={icon} title={reader.status}/>;
        }
        else if (activeReaderId) {
            return <FAIcon className='success-color' icon='wifi'/>;
        }

        return <FAIcon className='secondary-color' icon='wifi'/>;
    };

    render() {
        const {stripe: {readers}, error} = this.state;
        return (
            <div className='card'>
                <div className='card-divider'>
                Stripe Terminal Card Readers
                </div>
                <div className='card-section padding-vertical-1'>
                    {readers
                        ? readers.map(this.renderCardReader)
                        : !error && <Spinner/>}
                    {error && <AlertCallout text={error}/>}
                </div>
            </div>
        );
    }
}