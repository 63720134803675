import React from 'react';
import PropTypes from 'prop-types';
import FAIcon from '../utilities/FAIcon';

const AddRemoveButtons = ({className, insert, remove}) =>
    <div className={`${className} tiny button-group margin-right-1`}>
        <a className='success button' onClick={insert}><FAIcon icon='plus'/></a>
        {remove && <a className='secondary button' onClick={remove}><FAIcon icon='minus'/></a>}
    </div>;

AddRemoveButtons.propTypes = {
    className: PropTypes.string,
    insert: PropTypes.func,
    remove: PropTypes.func
};

export default AddRemoveButtons;