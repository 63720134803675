import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Rails from '@rails/ujs';
import {notify} from 'react-notify-toast';
import Spinner from '../utilities/Spinner';
import UserWidget from '../users/UserWidget';
import DialogCloseButton from '../dialog_buttons/DialogCloseButton';

export default class JobAssignmentDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {allUsers: [], users: [], loading: true};
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        Rails.ajax({
            url: '/users/active.json',
            type: 'get',
            success: users => {
                if (this._isMounted) {
                    this.setState({allUsers: users, users, loading: false});
                }
            },
            error: error => {
                if (this._isMounted) {
                    notify.show(error, 'error');
                    this.setState({allUsers: [], users: [], loading: false});
                }
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSearch = e => {
        const query = e.target.value.toLowerCase().trim();
        const users = this.state.allUsers.filter(user => user.fullName.toLowerCase().match(query));
        this.setState({users});
    };

    assignUser = user => {
        if (this.props.assignee && this.props.assignee.id === user.id) {
            this.props.callback(null);
        }
        else {
            this.props.callback(user);
        }
    };

    renderUser = user =>
        <div className='tr' key={user.id} onClick={() => this.assignUser(user)}>
            <div className='grid-x grid-margin-x align-middle'>
                <div className='shrink cell'>
                    <UserWidget user={user}/>
                </div>
                <div className='auto cell'>
                    {this.props.assignee && this.props.assignee.id === user.id &&
                    <span className='success label show-for-medium'>
                        Assigned
                    </span>}
                </div>
                <div className='shrink cell'>
                    {this.props.assignee && this.props.assignee.id === user.id
                        ? <a className='clear alert button'>Unassign</a>
                        : <a className='clear primary button'>Assign</a>}
                </div>
            </div>
        </div>;

    render() {
        return (
            <div className='reveal' id='job-assignment-dialog'>
                <h2>Assign This Job</h2>
                <div className='input-group'>
                    <label className='input-group-label' htmlFor='search-users'><i
                        className='fa fa-search'/></label>
                    <input className='input-group-field' id='search-users' onChange={this.handleSearch}
                        placeholder='search' type='search'/>
                </div>

                {this.state.loading ? <Spinner/> : <div className='table'>
                    {this.state.users.map(this.renderUser)}
                </div>}

                <DialogCloseButton/>
            </div>
        );
    }
}

JobAssignmentDialog.propTypes = {
    assignee: PropTypes.object,
    callback: PropTypes.func.isRequired,
    job: PropTypes.object.isRequired
};