import React from 'react';
import PropTypes from 'prop-types';

const JobUrgency = ({urgency}) => {
    switch (urgency) {
    case 'Low':
        return <span className='success label'>{urgency}</span>;
    case 'Medium':
        return <span className='warning label'>{urgency}</span>;
    case 'High':
        return <span className='alert label'>{urgency}</span>;
    default:
        return <span className='info label'>{urgency}</span>;
    }
};

JobUrgency.propTypes = {
    urgency: PropTypes.oneOf(['Low', 'Medium', 'High']).isRequired
};

export default JobUrgency;