import React from 'react';
import PropTypes from 'prop-types';
import {ROW_SCHEMA} from './Schema';
import InputField from '../form_fields/InputField';
import CheckBox from '../form_fields/CheckBox';
import Util from '../Util';

const CheckBoxRow = ({row, onChange}) =>
    <div className='grid-x grid-margin-x align-middle'>
        <div className='auto cell'>
            <div className='grid-x align-middle'>
                <div className='shrink cell'>
                    <CheckBox callback={value => onChange(row, ROW_SCHEMA.INCLUDED, value)}
                        checked={row[ROW_SCHEMA.INCLUDED]}/>
                </div>
                <div className='auto cell'>
                    <InputField onChange={value => onChange(row, ROW_SCHEMA.NAME, value)}
                        placeholder='name' value={row.name}/>
                </div>
            </div>
        </div>
        {Util.defined(row.text) && <div className='shrink cell'>
            <InputField onChange={value => onChange(row, ROW_SCHEMA.TEXT, value)}
                placeholder='option' value={row.text}/>
        </div>}
    </div>;

CheckBoxRow.propTypes = {
    onChange: PropTypes.func.isRequired,
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
        included: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
        text: PropTypes.string
    }).isRequired
};

export default CheckBoxRow;