import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {notify} from 'react-notify-toast';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import Rails from '@rails/ujs';
import JobAssignmentDialog from '../dialogs/JobAssignmentDialog';
import UserAvatar from '../users/UserAvatar';
import UserWidget from '../users/UserWidget';

export default class AssignJobButton extends PureComponent {
    constructor(props) {
        super(props);
        this.dialog = null;
        this.state = {open: false};
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openJobAssignmentDialog = () => {
        if (!this.dialog) {
            this.dialog = new Foundation.Reveal($('#job-assignment-dialog'));
        }

        this.dialog.open();
    };

    handleAssign = () => {
        this.setState({open: true}, this.openJobAssignmentDialog);
    };

    assignJob = assignee => {
        const data = new FormData();
        data.append('user_id', assignee ? assignee.id : '');
        Rails.ajax({
            url: `/jobs/${this.props.job.id}/assign`,
            type: 'put',
            data,
            success: () => {
                if (this._isMounted) {
                    this.dialog.close();
                    notify.show('Job assignment updated.', 'success');
                    this.props.job.assignee = assignee;
                    this.forceUpdate();
                }
            },
            error: error => {
                notify.show(error, 'error');
            }
        });
    };

    renderUser = () =>
        this.props.widget ? <UserWidget user={this.props.job.assignee}/>
            : <UserAvatar user={this.props.job.assignee}/>;

    renderAssignButton = () => this.props.editable ? <a className='clear margin-bottom-0 button'>Assign</a> : 'No one';

    render() {
        return (
            <div>
                <div onClick={this.props.editable ? this.handleAssign : null}>
                    {this.props.job.assignee
                        ? <div className={this.props.editable ? 'widget-link' : null}>{this.renderUser()}</div>
                        : this.renderAssignButton()}
                </div>
                {this.state.open && <JobAssignmentDialog assignee={this.props.job.assignee}
                    callback={this.assignJob} job={this.props.job}/>}
            </div>
        );
    }
}

AssignJobButton.defaultProps = {
    widget: false
};

AssignJobButton.propTypes = {
    editable: PropTypes.bool.isRequired,
    job: PropTypes.object.isRequired,
    widget: PropTypes.bool
};