import React from 'react';
import PropTypes from 'prop-types';
import {ROW_SCHEMA} from '../forms/Schema';
import InputField from '../form_fields/InputField';
import CheckBox from '../form_fields/CheckBox';
import MoneyInput from '../form_fields/MoneyInput';
import SimpleSelect from '../form_fields/SimpleSelect';

const MoneyRow = ({quickbooksClasses, quickbooksServices, row, onChange}) =>
    <div className='grid-x grid-margin-x align-middle'>
        {quickbooksServices && <div className='shrink cell'>
            <SimpleSelect
                items={quickbooksServices}
                onChange={value => onChange(row, ROW_SCHEMA.QUICKBOOKS_SERVICE, value)}
                prompt='QuickBooks Service' value={row[ROW_SCHEMA.QUICKBOOKS_SERVICE]}/>
        </div>}
        {quickbooksClasses && <div className='shrink cell'>
            <SimpleSelect
                items={quickbooksClasses}
                onChange={value => onChange(row, ROW_SCHEMA.QUICKBOOKS_CLASS, value)}
                prompt='QuickBooks Class' value={row[ROW_SCHEMA.QUICKBOOKS_CLASS]}/>
        </div>}
        <div className='small-3 cell'>
            <InputField onChange={value => onChange(row, ROW_SCHEMA.NAME, value)}
                placeholder='name' value={row[ROW_SCHEMA.NAME]}/>
        </div>
        {!quickbooksClasses && !quickbooksServices && <div className='auto cell'>
            <InputField onChange={value => onChange(row, ROW_SCHEMA.TEXT, value)}
                placeholder='optional description' value={row[ROW_SCHEMA.TEXT]}/>
        </div>}
        <div className='small-2 cell'>
            <MoneyInput amount={row[ROW_SCHEMA.AMOUNT]}
                callback={value => onChange(row, ROW_SCHEMA.AMOUNT, value)}/>
        </div>
        <div className='shrink cell'>
            <CheckBox callback={value => onChange(row, ROW_SCHEMA.INCLUDED, value)}
                checked={row[ROW_SCHEMA.INCLUDED]}/>
        </div>
        {quickbooksClasses && quickbooksServices && <div className='small-12 cell'>
            <InputField onChange={value => onChange(row, ROW_SCHEMA.TEXT, value)}
                placeholder='optional description' value={row[ROW_SCHEMA.TEXT]}/>
        </div>}
    </div>;

MoneyRow.propTypes = {
    onChange: PropTypes.func.isRequired,
    quickbooksClasses: PropTypes.array,
    quickbooksServices: PropTypes.array,
    row: PropTypes.shape({
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        id: PropTypes.string.isRequired,
        included: PropTypes.bool.isRequired,
        name: PropTypes.string,
        text: PropTypes.string
    }).isRequired
};

export default MoneyRow;