import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DialogCloseButton from '../dialog_buttons/DialogCloseButton';
import DateField from '../form_fields/DateField';

export default class UsersSearchDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            createdFrom: props.filters.createdFrom,
            createdTo: props.filters.createdTo,
            lastSignInAtFrom: props.filters.lastSignInAtFrom,
            lastSignInAtTo: props.filters.lastSignInAtTo
        };
    }

    handleClearFilters = () => {
        const filters = {createdFrom: null, createdTo: null, lastSignInAtFrom: null, lastSignInAtTo: null};
        this.setState({...filters}, () => this.props.callback({}));
    };

    handleSubmit = e => {
        this.props.callback(this.state);
        e.preventDefault();
    };

    render() {
        return (
            <div
                className='reveal'
                id='search-filters-dialog'
            >
                <p className='lead'>Enable Search Filters</p>
                <form
                    id='search-form'
                    onSubmit={this.handleSubmit}
                >
                    <label><b>Create Date Range</b></label>
                    <div className='grid-x grid-margin-x'>
                        <div className='auto cell'>
                            <DateField
                                name='createdFrom'
                                onChange={createdFrom =>
                                    this.setState({createdFrom: new Date(createdFrom).toISOString()})}
                                value={this.state.createdFrom}
                            />
                        </div>
                        <div className='shrink cell'>
                            <label className='middle lead'>to</label>
                        </div>
                        <div className='auto cell'>
                            <DateField
                                name='createdTo'
                                onChange={createdTo =>
                                    this.setState({createdTo: new Date(createdTo).toISOString()})}
                                value={this.state.createdTo}
                            />
                        </div>
                    </div>

                    <label><b>Last Sign In Date Range</b></label>
                    <div className='grid-x grid-margin-x'>
                        <div className='auto cell'>
                            <DateField
                                name='lastSignInAtFrom'
                                onChange={lastSignInAtFrom =>
                                    this.setState({lastSignInAtFrom: new Date(lastSignInAtFrom).toISOString()})}
                                value={this.state.lastSignInAtFrom}
                            />
                        </div>
                        <div className='shrink cell'>
                            <label className='middle lead'>to</label>
                        </div>
                        <div className='auto cell'>
                            <DateField
                                name='lastSignInAtTo'
                                onChange={lastSignInAtTo =>
                                    this.setState({lastSignInAtTo: new Date(lastSignInAtTo).toISOString()})}
                                value={this.state.lastSignInAtTo}
                            />
                        </div>
                    </div>

                    <div className='grid-x grid-margin-x align-justify'>
                        <div className='shrink cell'>
                            <button
                                className='margin-bottom-0 secondary button'
                                data-close=''
                                onClick={this.handleClearFilters}
                                type='button'
                            >
                                <i className='fa fa-times fa-fw'/>Clear Filters
                            </button>
                        </div>
                        <div className='auto cell'>
                            <button
                                className='expanded margin-bottom-0 primary button'
                                data-close=''
                                type='submit'
                            >
                                <i className='fa fa-filter fa-fw'/>Apply Filters
                            </button>
                        </div>
                    </div>
                </form>

                <DialogCloseButton/>
            </div>
        );
    }
}

UsersSearchDialog.propTypes = {
    callback: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired
};