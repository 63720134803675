import React, {PureComponent} from 'react';
import StripeIcon from '../assets/images/stripe.svg';
import Rails from '@rails/ujs';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import DialogCloseButton from './dialog_buttons/DialogCloseButton';
import {AlertCallout, InfoCallout, SuccessCallout, WarningCallout} from './utilities/Callout';
import FAIcon from './utilities/FAIcon';
import Cookies from 'js-cookie';
import StripeTerminal from './StripeTerminal';
import MoneyInput from './form_fields/MoneyInput';
import PropTypes from 'prop-types';

/* global Rollbar */
export default class TerminalPayment extends PureComponent {
    constructor(props) {
        super(props);

        this.clientSecret = null;
        this.activeReaderId = null;
        this.state = {
            addressLine1: props.addressLine1 || '',
            amount: props.amount?.toString(),
            connected: false,
            cardNumber: '',
            cardMonth: '',
            cardYear: '',
            cardCvc: '',
            email: props.email || '',
            error: null,
            estimateId: props.estimateId || '',
            jobId: props.jobId || '',
            method: 'terminal',
            initializingPayment: false,
            waitingOnCustomer: false,
            processingPayment: false,
            payment: null,
            stripe: null,
            paymentSucceeded: false,
            collectingPayment: false,
            breakpoint: Foundation.MediaQuery.current
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        $(window).resize(this.handleResize);
        $(document).on('closed.zf.reveal', this.cancelPayment);
    }

    componentWillUnmount() {
        this._isMounted = false;
        $(window).off('resize', this.handleResize);

        if (this.dialog) {
            this.dialog.destroy();
        }
    }

    handleResize = () => {
        if (this.state.breakpoint !== Foundation.MediaQuery.current) {
            this.setState({breakpoint: Foundation.MediaQuery.current});
        }
    };

    initialize = () => {
        this.setState({error: null});
        this.activeReaderId = Cookies.get('activeReaderId');
        StripeTerminal.create().then(stripe => {
            this.setState({stripe});
            const reader = stripe.readers.find(r => r.id === this.activeReaderId);

            if (reader) {
                this.connectReader(reader);
            }
        }).catch(error => {
            this.setState({error: error.message});
        });
    };

    openDialog = () => {
        const {connected} = this.state;

        if (!this.dialog) {
            this.dialog = new Foundation.Reveal($('#stripe-terminal'));
        }

        this.dialog.open();

        if (!connected) {
            this.initialize();
        }
    };

    connectReader = reader => {
        const {stripe} = this.state;
        stripe.instance.connectReader(reader).then(connectResult => {
            if (connectResult.error) {
                this.setState({error: connectResult.error.message});
            }
            else {
                this.setState({connected: true});
            }
        });
    };

    fetchPaymentIntent = () => new Promise((resolve, reject) => {
        const {amount, email, estimateId, jobId} = this.state;
        const data = new FormData();
        data.append('amount', amount);
        data.append('receipt_email', email);
        data.append('estimate_id', estimateId);
        data.append('job_id', jobId);
        data.append('address_line1', this.state.addressLine1);

        Rails.ajax({
            url: '/stripe/create_payment_intent',
            type: 'post',
            data,
            success: response => {
                if (this._isMounted) {
                    this.clientSecret = response.client_secret;
                    resolve(response.client_secret);
                }
            },
            error: error => {
                if (this._isMounted) {
                    this.clientSecret = null;
                    reject(new Error(error));
                }
            }
        });
    });

    collectPayment = async() => {
        const {stripe, addressLine1} = this.state;

        if (!addressLine1) {
            this.setState({error: 'Job Address is required to collect payment.'});
            return;
        }

        this.setState({error: null, initializingPayment: true});
        const clientSecret = this.clientSecret || await this.fetchPaymentIntent().catch(error => {
            this.setState({error: error.message});
        });

        if (!clientSecret) {
            this.setState({initializingPayment: false});
            return;
        }

        this.setState({initializingPayment: false, waitingOnCustomer: true});

        stripe.instance.collectPaymentMethod(clientSecret).then(result => {
            if (result.error) {
                this.setState({error: result.error.message});
            }
            else {
                this.processPayment(result.paymentIntent);
            }
        }).catch(error => {
            this.setState({error: error.message});
        }).finally(() => {
            this.setState({waitingOnCustomer: false});
        });
    };

    processPayment = paymentIntent => {
        const {stripe} = this.state;
        this.setState({waitingOnCustomer: false, collectingPayment: true});
        stripe.instance.processPayment(paymentIntent).then(result => {
            if (result.error) {
                this.setState({error: result.error.message});
            }
            // when processing debit cards, the payment intent is already succeeded
            else if (result.paymentIntent.status === 'succeeded') {
                this.savePayment(result.paymentIntent.charges.data[0]);
                this.setState({paymentSucceeded: true, payment: result.paymentIntent});
                this.clientSecret = null;
            }
            // when processing credit cards, the payment intent needs to be captured
            else {
                this.capturePaymentIntent(result.paymentIntent.id);
            }
        }).catch(error => {
            this.setState({error: error.message});
        }).finally(() => {
            this.setState({collectingPayment: false});
        });
    };

    processPaymentManually = () => { // eslint-disable-line max-statements
        const {cardNumber, cardMonth, cardYear, cardCvc, amount, email, addressLine1, estimateId, jobId} = this.state;

        // Check if job address is present
        if (!addressLine1) {
            this.setState({error: 'Job Address is required to process payment manually.'});
            return;
        }

        this.setState({error: null, processingPayment: true});
        const data = new FormData();
        data.append('credit_card_number', cardNumber);
        data.append('expiry_month', cardMonth);
        data.append('expiry_year', cardYear);
        data.append('cvc', cardCvc);
        data.append('amount', amount);
        data.append('receipt_email', email);
        data.append('estimate_id', estimateId);
        data.append('job_id', jobId);
        data.append('address_line1', addressLine1);

        Rails.ajax({
            url: '/stripe/process_credit_card_manually',
            type: 'post',
            data,
            success: response => {
                if (this._isMounted) {
                    this.savePayment(response);
                    this.setState({payment: response, paymentSucceeded: true});
                }
            },
            error: error => {
                if (this._isMounted) {
                    this.setState({error: error});
                }
            },
            complete: () => {
                if (this._isMounted) {
                    this.setState({processingPayment: false});
                }
            }
        });
    };

    capturePaymentIntent = paymentIntentId => {
        const {estimateId, jobId} = this.state;
        this.setState({processingPayment: true});
        const data = new FormData();
        data.append('id', paymentIntentId);
        data.append('estimate_id', estimateId);
        data.append('job_id', jobId);

        Rails.ajax({
            url: '/stripe/capture_payment_intent',
            type: 'post',
            data,
            success: response => {
                if (this._isMounted) {
                    this.savePayment(response.charges.data[0]);
                    this.setState({payment: response});
                    this.clientSecret = null;
                }
            },
            error: error => {
                if (this._isMounted) {
                    this.setState({error});
                }
            },
            complete: () => {
                if (this._isMounted) {
                    this.setState({paymentSucceeded: true, processingPayment: false});
                }
            }
        });
    };

    savePayment = charge => {
        const dataObj = {};
        const data = new FormData();
        const paymentMethod = this.state.method === 'manual' ? 'STRIPE' : 'TERMINAL';
        data.append('job_id', this.state.jobId);
        data.append('notify', true);
        data.append('estimate_id', this.state.estimateId);
        data.append('payment[amount]', charge.amount / 100.0);
        data.append('payment[method]', paymentMethod);
        data.append('payment[description]', charge.description);
        data.append('payment[stripe_charge_id]', charge.id);
        data.append('payment[stripe_receipt_url]', charge.receipt_url);
        data.forEach((value, key) => {
            dataObj[key] = value;
        });

        Rails.ajax({
            url: '/payments.json',
            type: 'post',
            data,
            error: error => {
                Rollbar.error(`Failed to save payment: ${error.message}`, {charge, data: dataObj});

                if (this._isMounted) {
                    this.setState({
                        error: `Although the transaction succeeded, the payment could not be saved 
                        for the following reason: ${error.message}. You need to manually record this payment.`
                    });
                }
            }
        });
    };

    paymentInProgress = () => {
        const {collectingPayment, initializingPayment, processingPayment, waitingOnCustomer} = this.state;
        return initializingPayment || waitingOnCustomer || collectingPayment || processingPayment;
    };

    reset = () => {
        this.clientSecret = null;
        this.setState({
            cardNumber: '',
            cardMonth: '',
            cardYear: '',
            cardCvc: '',
            error: null,
            initializingPayment: false,
            waitingOnCustomer: false,
            processingPayment: false,
            paymentSucceeded: false,
            collectingPayment: false
        });
    };

    cancelPayment = () => {
        const {stripe, waitingOnCustomer} = this.state;

        if (waitingOnCustomer) {
            stripe.instance.cancelCollectPaymentMethod().finally(this.reset);
        }
    };

    renderPaymentStatus = () => {
        const {
            collectingPayment,
            connected,
            error,
            initializingPayment,
            paymentSucceeded,
            payment,
            processingPayment,
            stripe,
            waitingOnCustomer
        } = this.state;
        let message;

        if (error) {
            return <AlertCallout text={error}/>;
        }
        else if (!stripe) {
            message = 'Establishing connection to Stripe...';
        }
        else if (!this.activeReaderId) {
            return <AlertCallout text='You have not selected a preferred Stripe terminal.'/>;
        }
        else if (!connected) {
            message = 'Connecting to preferred Stripe terminal...';
        }
        else if (initializingPayment) {
            message = 'Initializing payment request...';
        }
        else if (waitingOnCustomer) {
            return <WarningCallout>
                <div className='grid-x grid-margin-x align-middle'>
                    <div className='auto cell'>
                        <FAIcon icon='spinner fa-spin' text='Waiting on customer to complete payment...'/>
                    </div>
                    <div className='shrink cell'>
                        <a className='tiny alert button margin-0' onClick={this.cancelPayment}>
                            Cancel
                        </a>
                    </div>
                </div>
            </WarningCallout>;
        }
        else if (collectingPayment) {
            message = 'Collecting payment information...';
        }
        else if (processingPayment) {
            message = 'Finalizing payment request...';
        }
        else if (paymentSucceeded) {
            return <SuccessCallout>
                <div className='grid-x grid-margin-x align-middle'>
                    <div className='auto cell'>
                        Payment successful.
                    </div>
                    <div className='shrink cell'>
                        <a className='tiny button margin-0'
                            href={payment.charges.data[0].receipt_url}
                            rel='noreferrer' target='_blank'>
                            Print Receipt
                        </a>
                    </div>
                </div>
            </SuccessCallout>;
        }
        else {
            return null;
        }

        return <InfoCallout>
            <FAIcon icon='spinner fa-spin' text={message}/>
        </InfoCallout>;
    };

    renderManualPaymentStatus = () => {
        const {
            error,
            payment,
            paymentSucceeded,
            processingPayment
        } = this.state;
        let message;

        if (error) {
            return <AlertCallout text={error}/>;
        }
        else if (processingPayment) {
            message = 'Processing payment request...';
        }
        else if (paymentSucceeded) {
            return <SuccessCallout>
                <div className='grid-x grid-margin-x align-middle'>
                    <div className='auto cell'>
                        Payment successful.
                    </div>
                    <div className='shrink cell'>
                        <a className='tiny button margin-0'
                            href={payment.receipt_url}
                            rel='noreferrer' target='_blank'>
                            Print Receipt
                        </a>
                    </div>
                </div>
            </SuccessCallout>;
        }
        else {
            return null;
        }

        return <InfoCallout>
            <FAIcon icon='spinner fa-spin' text={message}/>
        </InfoCallout>;
    };

    renderPaymentForm = () => {
        const {amount, email, addressLine1} = this.state;
        return <form>
            <div className='grid-x grid-margin-x align-bottom'>
                <div className='medium-auto cell'>
                    <label htmlFor='amount'>Receipt Email</label>
                    <input
                        onChange={e => this.setState({email: e.target.value})}
                        placeholder='Optional'
                        type='email'
                        value={email}/>
                </div>
                <div className='medium-auto cell'>
                    <label className='required' htmlFor='addressLine1'>Job Address</label>
                    <input
                        id='addressLine1'
                        onChange={e => this.setState({addressLine1: e.target.value})}
                        required
                        type='text'
                        value={addressLine1}/>
                </div>
                <div className='medium-3 cell'>
                    <label className='required' htmlFor='amount'>Amount</label>
                    <MoneyInput amount={amount} callback={value => this.setState({amount: value})}/>
                </div>
            </div>
        </form>;
    };

    renderManualPaymentForm = () => {
        const {cardNumber, cardMonth, cardYear, cardCvc} = this.state;
        return <fieldset className='fieldset'>
            <legend>Credit Card Information</legend>
            <input
                inputMode='numeric'
                onChange={e => this.setState({cardNumber: e.target.value})}
                placeholder='Credit card number'
                type='text'
                value={cardNumber}/>
            <div className='grid-x grid-margin-x'>
                <div className='auto cell'>
                    <select onChange={e => this.setState({cardMonth: e.target.value})} value={cardMonth}>
                        <option disabled value=''>Month</option>
                        <option value={1}>1 - Jan</option>
                        <option value={2}>2 - Feb</option>
                        <option value={3}>3 - Mar</option>
                        <option value={4}>4 - Apr</option>
                        <option value={5}>5 - May</option>
                        <option value={6}>6 - Jun</option>
                        <option value={7}>7 - Jul</option>
                        <option value={8}>8 - Aug</option>
                        <option value={9}>9 - Sep</option>
                        <option value={10}>10 - Oct</option>
                        <option value={11}>11 - Nov</option>
                        <option value={12}>12 - Dec</option>
                    </select>
                </div>
                <div className='auto cell'>
                    <select onChange={e => this.setState({cardYear: e.target.value})} value={cardYear}>
                        <option disabled value=''>Year</option>
                        {Array.from({length: 10}).map((entry, index) =>
                            <option
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={index + new Date().getFullYear()}
                                value={index + new Date().getFullYear()}>
                                {index + new Date().getFullYear()}
                            </option>)}
                    </select>
                </div>
            </div>
            <input
                inputMode='numeric'
                onChange={e => this.setState({cardCvc: e.target.value})}
                placeholder='CVC'
                type='number' value={cardCvc}/>
        </fieldset>;
    };

    render() {
        if (!Foundation.MediaQuery.is(this.props.breakpoint)) {
            return <div/>;
        }

        const paymentInProgress = this.paymentInProgress();
        const {amount, connected, method, paymentSucceeded} = this.state;
        const {estimateId} = this.props;
        return (
            <div>
                <a className='terminal-status' onClick={this.openDialog}>
                    <img alt='Stripe Logo' src={StripeIcon} width={60}/>
                </a>
                <div className='reveal' id='stripe-terminal'>
                    <div className='grid-x grid-margin-x align-middle'>
                        <div className='shrink cell'>
                            <h2>Accept Stripe Payment</h2>
                        </div>
                        <div className='auto cell'>
                            <button
                                className='tiny secondary button margin-0'
                                disabled={paymentInProgress}
                                onClick={this.reset}
                                type='button'
                            >Reset
                            </button>
                        </div>
                    </div>
                    <div className='grid-x'>
                        <div className='shrink cell'>
                            <input
                                checked={method === 'terminal'}
                                id='terminal'
                                name='method'
                                onChange={() => {
                                    this.reset();
                                    this.setState({method: 'terminal'});
                                }}
                                type='radio'
                                value='terminal'/>
                            <label htmlFor='terminal'>Use Stripe Terminal</label>
                        </div>
                        <div className='auto cell'>
                            <input
                                checked={method === 'manual'}
                                id='manual'
                                name='method'
                                onChange={() => {
                                    this.reset();
                                    this.setState({method: 'manual'});
                                }}
                                type='radio'
                                value='manual'/>
                            <label htmlFor='manual'>Enter Card Manually</label>
                        </div>
                    </div>
                    {!estimateId && <WarningCallout>
                        This payment will not be assigned to an estimate.
                        If you wish to pay an estimate, open the estimate and then initiate payment.
                    </WarningCallout>}
                    {this.renderPaymentForm()}
                    {method === 'terminal' && connected && !paymentInProgress && !paymentSucceeded && <button
                        className='large expanded button'
                        disabled={amount <= 0}
                        onClick={this.collectPayment}
                        type='button'>
                        Initiate Payment
                    </button>}
                    {method === 'terminal' && this.renderPaymentStatus()}
                    {method === 'manual' && this.renderManualPaymentForm()}
                    {method === 'manual' && !paymentInProgress && !paymentSucceeded && <button
                        className='large expanded button'
                        disabled={amount <= 0}
                        onClick={this.processPaymentManually}
                        type='button'>
                        Submit Payment
                    </button>}
                    {method === 'manual' && this.renderManualPaymentStatus()}
                    <DialogCloseButton/>
                </div>
            </div>
        );
    }
}

TerminalPayment.propTypes = {
    addressLine1: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    breakpoint: PropTypes.string.isRequired,
    email: PropTypes.string,
    estimateId: PropTypes.number,
    jobId: PropTypes.number
};