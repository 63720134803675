import React, {PureComponent} from 'react';
import Util from '../Util';
import Rails from '@rails/ujs';
import PropTypes from 'prop-types';
import {EXTERIOR_WORK_ORDER, ROOFING_WORK_ORDER} from './InitialState';
import FormSection from '../forms/FormSection';
import {ROW_TYPE} from '../forms/Schema';
import DateField from '../form_fields/DateField';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import InputField from '../form_fields/InputField';

/* global Turbolinks:true */
export default class WorkOrderForm extends PureComponent {
    static getInitialState(category) {
        switch (category) {
        case 'Exterior':
            return Util.clone(EXTERIOR_WORK_ORDER);
        case 'Roofing':
            return Util.clone(ROOFING_WORK_ORDER);
        default:
            return null;
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            title: '',
            projectedStartDate: '',
            sections: WorkOrderForm.getInitialState(this.props.category)
        };

        if (props.workOrder) {
            this.state = {
                title: props.workOrder.title || '',
                projectedStartDate: props.workOrder.projectedStartDate,
                sections: props.workOrder.contents
            };
        }
    }

    createOrUpdateWorkOrder = () => {
        if (this.state.projectedStartDate.length === 0) {
            notify.show('Please provide a projected start date.', 'error');
        }
        else {
            const {category, workOrder, jobId, estimateId} = this.props;
            const {projectedStartDate, title, sections} = this.state;
            const data = new FormData();

            if (estimateId) {
                data.append('work_order[estimate_id]', estimateId);
            }

            data.append('work_order[projected_start_date]', projectedStartDate);
            data.append('work_order[category]', category);
            data.append('work_order[title]', title);
            data.append('work_order[contents]', JSON.stringify(sections));

            if (workOrder) {
                Rails.ajax({
                    url: `/work-orders/${workOrder.id}`,
                    type: 'put',
                    dataType: 'json',
                    data
                });
            }
            else {
                Rails.ajax({
                    url: `/jobs/${jobId}/work-orders`,
                    type: 'post',
                    dataType: 'json',
                    data
                });
            }
        }
    };

    addObject = (list, type, index = null) => {
        let row;

        switch (type) {
        case ROW_TYPE.CUSTOMER_OPTION:
            row = {id: Util.uuid(), name: '', text: '', type};
            break;
        case ROW_TYPE.DATE_FIELD:
            row = {id: Util.uuid(), name: '', date: '', type};
            break;
        case ROW_TYPE.DATETIME_FIELD:
            row = {id: Util.uuid(), name: '', date: '', type};
            break;
        case ROW_TYPE.MONEY_OPTIONAL:
            row = {id: Util.uuid(), name: '', text: '', amount: 0.0, included: false, type};
            break;
        case ROW_TYPE.SECTION:
            row = {id: Util.uuid(), title: '', rows: [], type};
            break;
        case ROW_TYPE.SERVICE:
            row = {id: Util.uuid(), name: '', included: false, type};
            break;
        case ROW_TYPE.SERVICE_WITH_TEXT:
            row = {id: Util.uuid(), name: '', text: '', included: false, type};
            break;
        case ROW_TYPE.TEXT_BLOCK:
            row = {id: Util.uuid(), text: '', type};
            break;
        default:
            row = null;
        }

        list.splice((index || list.length) + 1, 0, row);
        this.forceUpdate();
    };

    copyObject = (list, index) => {
        this.addObject(list, list[index].type, index);
        this.forceUpdate(() => $('.dropdown').foundation());
    };

    removeObject = (list, index) => {
        list.splice(index, 1);
        this.forceUpdate();
    };

    updateField = (row, field, value) => {
        row[field] = value;
        this.forceUpdate();
    };

    renderProjectDates = () => {
        const {projectedStartDate} = this.state;
        return <div className='card'>
            <div className='card-section'>
                <div className='grid-x grid-margin-x'>
                    <div className='shrink cell'>
                        <label className='middle'>Projected start date</label>
                    </div>
                    <div className='auto cell'>
                        <DateField name='start_date' onChange={value => this.setState({projectedStartDate: value})}
                            value={projectedStartDate}/>
                    </div>
                </div>
            </div>
        </div>;
    };

    render() {
        const {title, sections} = this.state;
        return (
            <div className='work-order'>
                <div className='card'>
                    <div className='card-divider'>
                        Optional Title
                    </div>
                    <div className='card-section'>
                        <InputField
                            onChange={value => this.setState({title: value})}
                            placeholder='Enter a user-friendly title (OPTIONAL)'
                            value={title}/>
                    </div>
                </div>
                {this.renderProjectDates()}
                {this.state.sections.map((section, index) =>
                    <FormSection addField={this.addObject} copySelf={this.copyObject}
                        destroySelf={this.removeObject} key={section.id} onChange={this.updateField}
                        parentList={sections} section={section} selfIndex={index}/>)}
                <div className='grid-x grid-margin-x'>
                    <div className='shrink cell'>
                        <a className='large secondary button'
                            onClick={() =>
                                Turbolinks.visit(this.props.workOrder
                                    ? `/work-orders/${this.props.workOrder.id}`
                                    : `/estimates/${this.props.estimateId}`)}>
                            <i className='fa fa-times'/>Cancel
                        </a>
                    </div>
                    <div className='auto cell'>
                        <a className='large expanded success button'
                            onClick={this.createOrUpdateWorkOrder}>
                            {this.props.workOrder ? 'Update' : 'Create'} Work Order
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

WorkOrderForm.propTypes = {
    category: PropTypes.string.isRequired,
    estimateId: PropTypes.number,
    jobId: PropTypes.number.isRequired,
    workOrder: PropTypes.object
};