export const ROW_SCHEMA = {
    AMOUNT: 'amount',
    DATE: 'date',
    NAME: 'name',
    TEXT: 'text',
    QUICKBOOKS_SERVICE: 'quickbooksService',
    QUICKBOOKS_CLASS: 'quickbooksClass',
    INCLUDED: 'included',
    TITLE: 'title'
};

export const ROW_TYPE = {
    CUSTOMER_OPTION: 'CUSTOMER_OPTION',
    DATE_FIELD: 'DATE_FIELD',
    DATETIME_FIELD: 'DATETIME_FIELD',
    MONEY_OPTIONAL: 'MONEY_OPTIONAL',
    SECTION: 'SECTION',
    SERVICE: 'SERVICE',
    SERVICE_WITH_TEXT: 'SERVICE_WITH_TEXT',
    TEXT_BLOCK: 'TEXT_BLOCK'
};

export const ROW_TYPE_LIST = [
    {id: ROW_TYPE.SERVICE, text: 'Checkbox'},
    {id: ROW_TYPE.SERVICE_WITH_TEXT, text: 'Checkbox with option'},
    {id: ROW_TYPE.DATETIME_FIELD, text: 'Date with time'},
    {id: ROW_TYPE.DATE_FIELD, text: 'Date without time'},
    {id: ROW_TYPE.MONEY_OPTIONAL, text: 'Optional money field'},
    {id: ROW_TYPE.CUSTOMER_OPTION, text: 'Text with option'},
    {id: ROW_TYPE.TEXT_BLOCK, text: 'Text block'}
];