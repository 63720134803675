import React from 'react';
import QuickBooksLogo from '../../../../app/assets/images/quickbooks.svg';

const NotCompatibleWithQuickBooksMessage = () =>
    <div className='warning callout margin-bottom-2'>
        <div className='grid-x align-middle'>
            <div className='shrink cell'>
                <img alt='Quickbooks' className='margin-right-1' src={QuickBooksLogo} width='100'/>
            </div>
            <div className='auto cell'>
                This estimate is not compatible with QuickBooks because one or more optional money
                items have an invalid or missing QuickBooks service or class.
            </div>
        </div>
    </div>;

export default NotCompatibleWithQuickBooksMessage;