import React from 'react';
import PropTypes from 'prop-types';
import FAIcon from './FAIcon';

const Callout = ({icon, text, type, children}) =>
    <div className={`${type} callout`}>
        <div className='grid-x align-middle'>
            <div className='shrink cell'>
                <FAIcon icon={icon}/>
            </div>
            <div className='auto cell'>
                {text || children}
            </div>
        </div>
    </div>;

export const InfoCallout = ({text, children}) =>
    <Callout icon='info-circle' text={text} type='info'>{children}</Callout>;

export const SuccessCallout = ({text, children}) =>
    <Callout icon='check' text={text} type='success'>{children}</Callout>;

export const WarningCallout = ({text, children}) =>
    <Callout icon='warning' text={text} type='warning'>{children}</Callout>;

export const AlertCallout = ({text, children}) =>
    <Callout icon='times-circle' text={text} type='alert'>{children}</Callout>;

Callout.propTypes = {
    children: PropTypes.any,
    icon: PropTypes.oneOf(['info-circle', 'check', 'warning', 'times-circle']).isRequired,
    text: PropTypes.string,
    type: PropTypes.oneOf(['info', 'success', 'warning', 'alert']).isRequired
};
InfoCallout.propTypes = {children: PropTypes.any, text: PropTypes.string};
SuccessCallout.propTypes = {children: PropTypes.any, text: PropTypes.string};
WarningCallout.propTypes = {children: PropTypes.any, text: PropTypes.string};
AlertCallout.propTypes = {children: PropTypes.any, text: PropTypes.string};