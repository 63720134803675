import {ROW_TYPE} from '../forms/Schema';
import Util from '../Util';

/* eslint-disable max-len */
export const EXTERIOR_ESTIMATE = [
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                name: 'Prepare safe site',
                type: ROW_TYPE.SERVICE,
                included: true
            },
            {
                id: Util.uuid(),
                name: 'Assess for repairs',
                type: ROW_TYPE.SERVICE,
                included: true
            },
            {
                id: Util.uuid(),
                name: 'Installation of chosen products',
                type: ROW_TYPE.SERVICE,
                included: true
            },
            {
                id: Util.uuid(),
                name: 'Clean site & magnet sweep',
                type: ROW_TYPE.SERVICE,
                included: true
            },
            {
                id: Util.uuid(),
                name: '40 point inspection of work & site',
                type: ROW_TYPE.SERVICE,
                included: true
            },
            {
                id: Util.uuid(),
                name: '6 Year Workmanship Warranty',
                type: ROW_TYPE.SERVICE,
                included: true
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Included Services'
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: 'Note: Board Repair prices subject to change to reflect current market price of product.',
                type: ROW_TYPE.TEXT_BLOCK
            },
            {
                id: Util.uuid(),
                name: 'Board repair (1x6 or 1x8)',
                text: '$5.50/lineal ft *if needed*',
                type: ROW_TYPE.CUSTOMER_OPTION
            },
            {
                id: Util.uuid(),
                name: 'Board repair (sheathing)',
                text: '$3.00/sqft *if needed*',
                type: ROW_TYPE.CUSTOMER_OPTION
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Building Notes'
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: 'Supply & Install labour & materials for the following:\n* 6" Prefinished Aluminum Fascia With Perforated Aluminum Soffits\n* Improve venting to attic by adding ventilation locations to current wood soffits.\n* Inspect condition of wood substrate where materials will be installed.\n* Large oversize soffit at front entrance to be covered with Aluminium Soffits',
                type: ROW_TYPE.TEXT_BLOCK
            },
            {
                id: Util.uuid(),
                name: 'Soffit & Fascia',
                text: '',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Soffit & Fascia System',
        subtotal: 0
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: 'Supply & Install labour & materials for the following:\n* Removal and Disposal of Old Eavestrough Materials\n* Installation of 5" Continuous Aluminum Eavestrough.\n* Installation of Larger 3x3" Downspouts.\n* Install 5\' Flip-up Extension to all downspouts where applicable.\n* Seal all corners / end caps / downspout outlet locations',
                type: ROW_TYPE.TEXT_BLOCK
            },
            {
                id: Util.uuid(),
                name: 'Gutter System',
                text: '',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'Gutter Guard',
                text: '',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Gutter System',
        subtotal: 0
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: 'Supply & Install labour & materials for the following:\n* Removal and Disposal of Old Materials \n* Inspect condition of wood substrate where materials will be installed & Repair if necessary\n* Install House wrap to improve Envelope system\n* Installation of (Siding Product of Choice)\n* Installation of (Trim System If applicable)\n* Caulk / Silicon where required',
                type: ROW_TYPE.TEXT_BLOCK
            },
            {
                id: Util.uuid(),
                name: 'Siding System',
                text: '',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'Premium Upgrade',
                text: '',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Siding System: KWP / Vinyl / Hardie Ect',
        subtotal: 0
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: 'Supply & Install labour & materials for the following:\n* Removal and Disposal of Old Materials (if required)\n* Install new Metal Cladding\n* Install New KWP Panel Over Post and Beams\n* Caulk & Touchup as required\n',
                type: ROW_TYPE.TEXT_BLOCK
            },
            {
                id: Util.uuid(),
                name: 'Cladding',
                text: '',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Custom Cladding: Metal or KWP (Post & Beams)',
        subtotal: 0
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: 'Due to instability in our market (product supply chain) we have encountered many sudden changes in our Material cost. The price on this estimate reflects current market prices. Should there be a price increase prior to our ability to get your project completed we may need to adjust the price accordingly. This would be a minimal adjustment when required. Thank you for understanding.',
                type: ROW_TYPE.TEXT_BLOCK
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Price Change Notice:'
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: '*To be entered here @ time of \'acceptance\' or as a revision after \'acceptance\'',
                type: ROW_TYPE.TEXT_BLOCK
            },
            {
                id: Util.uuid(),
                name: 'Eavestrough colour',
                text: '',
                type: ROW_TYPE.CUSTOMER_OPTION
            },
            {
                id: Util.uuid(),
                name: 'Fascia colour',
                text: '',
                type: ROW_TYPE.CUSTOMER_OPTION
            },
            {
                id: Util.uuid(),
                name: 'Soffit colour',
                text: '',
                type: ROW_TYPE.CUSTOMER_OPTION
            },
            {
                id: Util.uuid(),
                name: 'Siding colour',
                text: '',
                type: ROW_TYPE.CUSTOMER_OPTION
            },
            {
                id: Util.uuid(),
                name: 'Trim colour',
                text: '',
                type: ROW_TYPE.CUSTOMER_OPTION
            },
            {
                id: Util.uuid(),
                name: 'Cladding colour',
                text: '',
                type: ROW_TYPE.CUSTOMER_OPTION
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Material & colour selections'
    }
];

export const ROOFING_ESTIMATE = [
    {
        id: Util.uuid(), title: 'Included Services', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), name: 'Prepare safe site', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Remove & dispose (1) layer of old shingles + clean eaves trough', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Install 44" ice & water shield @ eaves + valleys + extra as needed', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Install synthetic roofing underlayment over entire roof surface', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Install new Roof Vents (#) Duraflo R50 vents', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Install (#) thermoplastic plumbing flashings', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Install (#) Primex Rv28 exhaust vents', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Install Shingle Application (choices below)', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Tar / caulk as needed + secure roof apparatus w/ self sealing screws', included: true, type: ROW_TYPE.SERVICE},
            {id: Util.uuid(), name: 'Final Inspection & 6 Year Workmanship Warranty', text: '', included: true, type: ROW_TYPE.SERVICE}
        ]
    },
    {
        id: Util.uuid(), title: 'Roofing Project', type: ROW_TYPE.SECTION, rows: [
            {
                id: Util.uuid(),
                name: 'Certainteed Brand',
                text: 'Re-roof w/ Certainteed Landmark shingles',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'IKO Brand',
                text: 'Re-roof w/ IKO Cambridge / Biltmore shingles',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'Shingle Color',
                text: '* enter here',
                type: ROW_TYPE.CUSTOMER_OPTION
            }
        ]
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                name: 'Certainteed Option',
                text: '4 Star \'Sure Start Plus\' 50 yr non pro-rated extended coverage (includes Certainteed brand underlayments) see pdf brochure',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'Certainteed Option',
                text: 'Landmark PRO - shingle upgrade - heavier shingle (250Lbs/sq) + Max Def color palette',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'Certainteed Option',
                text: 'NorthGate - Performance shingle: Ideal for colder climates - SBS modified asphalt shingle, better granule retention & excellent resistance due to damage from freeze/thaw cycle. UL2218 Class 4 impact resistant shingle. Max Definition color palette. 270LBS per square (see PDF brochure)',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'Certainteed Option',
                text: '\'Mountain Ridge\' - High profile hip & ridge capping - enhances roof line!',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'Either Brand Option',
                text: 'Drip edge flashing - supports 1st row of shingles + help prevent water from migrating behind eaves trough',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'Either Brand Option',
                text: 'Metal Valley App - 12"x12" 26ga pre-finished steel flashing - fortify sensitive areas of roof (color match shingle)',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'IKO Option',
                text: 'Dynasty / Regency - shingle upgrade - Armor Zone nail strip technology + BOLD color palette',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            },
            {
                id: Util.uuid(),
                name: 'IKO Option',
                text: '\'Ultra HP\' - High profile hip / ridge capping - enhances roof line!',
                type: ROW_TYPE.MONEY_OPTIONAL,
                amount: 0,
                included: false
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Optional Items to Consider + Notes',
        subtotal: 0
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: '* once shingles are removed we will take photos of any repairs or recommendations and communicate any updates with customer during the project\n* all prices subject to 5% GST\n* quoted prices are subject to change due to unstable market conditions - see notice below\n* 7/16" OSB  & Unforeseen Repairs --> these will be priced on a \'per job\' basis due to unstable lumber & construction material prices',
                type: ROW_TYPE.TEXT_BLOCK
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Notes'
    },
    {
        id: Util.uuid(),
        rows: [
            {
                id: Util.uuid(),
                text: 'Due to instability in our market (product supply chain) we have encountered many sudden changes in our Material cost. The price on this estimate reflects current market prices. Should there be a price increase prior to our ability to get your project completed we may need to adjust the price accordingly. This would be a minimal adjustment when required. Thank you for understanding.',
                type: ROW_TYPE.TEXT_BLOCK
            }
        ],
        type: ROW_TYPE.SECTION,
        title: 'Price Change Notice:'
    }
];

export const SERVICE_WORK_ESTIMATE = [
    {
        id: Util.uuid(), title: 'Service Work / Repair Description', type: ROW_TYPE.SECTION, rows: [
            {
                id: Util.uuid(),
                text: '* List observed issues\n' +
                    '* List what needs to be fixed and how',
                type: ROW_TYPE.TEXT_BLOCK
            },
            {id: Util.uuid(), name: 'Price', text: 'As described above', amount: 0.0, included: false, type: ROW_TYPE.MONEY_OPTIONAL}
        ]
    },
    {
        id: Util.uuid(), title: 'Additional work', type: ROW_TYPE.SECTION, rows: [
            {
                id: Util.uuid(),
                text: '* Upon site visit if a more involved repair is needed or if further issues are found - list items here',
                type: ROW_TYPE.TEXT_BLOCK
            },
            {id: Util.uuid(), name: 'Price', text: 'As described above', amount: 0.0, included: false, type: ROW_TYPE.MONEY_OPTIONAL}
        ]
    },
    {
        id: Util.uuid(), title: 'Notes & Recommendations', type: ROW_TYPE.SECTION, rows: [
            {
                id: Util.uuid(),
                text: '* List recommendations for customer\n' +
                    '* Note other issues\n' +
                    '* Note if customer declined any work ',
                type: ROW_TYPE.TEXT_BLOCK
            }
        ]
    }
];