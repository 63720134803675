import React, {PureComponent} from 'react';
import WorkOrdersTable from './WorkOrdersTable';
import Rails from '@rails/ujs';
import {notify} from 'react-notify-toast';
import Pagination from '../utilities/Pagination';
import WorkOrdersSearchDialog from '../dialogs/WorkOrdersSearchDialog';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {InfoCallout} from '../utilities/Callout';
import Mark from 'mark.js/dist/mark.es6';
import Spinner from '../utilities/Spinner';

export default class WorkOrdersPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            workOrders: [],
            query: props.filters.query || '',
            loading: props.totalCount > 0,
            page: Number(props.filters.page || 1),
            recordsPerPage: Number(props.filters.records || props.recordsPerPage),
            totalCount: 0,
            filters: props.filters
        };
        this.dialog = null;
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.totalCount > 0) {
            this.findWorkOrders();
        }
    }

    componentDidUpdate() {
        const instance = new Mark(document.querySelector('#work-orders-list'));
        instance.mark(this.state.query);
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this._timer) {
            clearTimeout(this._timer);
        }
    }

    openSearchDialog = () => {
        if (!this.dialog) {
            this.dialog = new Foundation.Reveal($('#search-filters-dialog'));
        }

        this.dialog.open();
    };

    findWorkOrders = (page = 1) => {
        const queryParams = `query=${this.state.query}`;
        const paginationParams = `page=${page}&records=${this.state.recordsPerPage}`;
        const filtersParams = $.param(this.state.filters);
        this._timer = setTimeout(() => this.setState({loading: true}), 250);
        Rails.ajax({
            url: `/work-orders/search.json?${filtersParams}&${paginationParams}&${queryParams}`,
            type: 'get',
            success: data => {
                if (this._isMounted) {
                    this.setState({workOrders: data.array, totalCount: data.totalCount, page, loading: false});
                }
            },
            error: error => {
                if (this._isMounted) {
                    notify.show(error, 'error');
                    this.setState({workOrders: [], page, loading: false});
                }
            },
            complete: () => {
                clearTimeout(this._timer);
            }
        });
    };

    filtersEnabledCount = () => {
        const {filters} = this.state;

        let count = 0;

        if (filters.from && filters.from.toString().length || filters.to && filters.to.toString().length) {
            count++;
        }

        if (filters.assignees && filters.assignees.length) {
            count++;
        }

        if (filters.status && filters.status.length) {
            count++;
        }

        if (filters.urgency && filters.urgency.length) {
            count++;
        }

        return count;
    };

    applySearchFilters = filters => {
        this.setState({filters}, this.findWorkOrders);
    };

    searchWorkOrders = e => {
        const query = e.target.value.trim();

        if (query.length === 0 || query.length > 2) {
            this.setState({query}, this.findWorkOrders);
        }
    };

    changeRecordsPerPage = e => {
        this.setState({recordsPerPage: Number(e.target.value)}, this.findWorkOrders);
    };

    changePage = page => {
        this.findWorkOrders(page);
    };

    renderNavigation = () =>
        <div className='grid-x grid-margin-x align-justify'>
            <div className='auto cell'>
                <div className='grid-x grid-margin-x align-middle'>
                    <div className='auto medium-shrink cell'>
                        <div className='input-group'>
                            <label className='input-group-label' htmlFor='text'>
                                <i className='fa fa-search'/>
                            </label>
                            <input className='input-group-field' defaultValue={this.props.filters.query} id='text'
                                name='text' onChange={this.searchWorkOrders} placeholder='search' type='search'/>
                            <div className='input-group-label relative' onClick={this.openSearchDialog}>
                                <i className='fa fa-filter'/><span className='show-for-medium'>Filter</span>
                                {this.filtersEnabledCount() > 0 && <b className='float info badge'>
                                    {this.filtersEnabledCount()}</b>}
                            </div>
                        </div>
                    </div>
                    <div className='shrink cell'>
                        <div className='input-group'>
                            <label className='input-group-label show-for-large' htmlFor='records'>
                                # per page
                            </label>
                            <select className='input-group-field' defaultValue={this.state.recordsPerPage}
                                id='records' onChange={this.changeRecordsPerPage}>
                                <option>5</option>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                                <option>250</option>
                            </select>
                        </div>
                    </div>
                    <div className='shrink cell show-for-large'>
                        <p>{this.renderRecordsShown()}</p>
                    </div>
                </div>
            </div>
        </div>;

    renderRecordsShown = () => {
        const from = (this.state.page - 1) * this.state.recordsPerPage + 1;
        const to = Math.min(this.state.page * this.state.recordsPerPage, this.state.totalCount);

        if (this.state.loading) {
            return <span><i className='fa fa-spinner fa-pulse'/>Loading data...</span>;
        }
        else if (this.state.totalCount === 0) {
            return null;
        }

        return <small>Records: {from}-{to} of {this.state.totalCount}</small>;
    };

    renderPagination = () =>
        <Pagination callback={this.changePage} page={this.state.page}
            recordsPerPage={this.state.recordsPerPage} totalCount={this.state.totalCount}/>;

    renderWorkOrders = () => <div>
        {this.renderPagination()}
        <WorkOrdersTable editable={this.props.editable} workOrders={this.state.workOrders}/>
        {this.renderPagination()}
    </div>;

    render() {
        return (
            <div id='work-orders-list'>
                {this.renderNavigation()}
                {this.props.totalCount === 0 && <InfoCallout text='You have not created any work orders yet...'/>}
                {this.state.loading && this.state.workOrders.length === 0 && <Spinner/>}
                {this.props.totalCount > 0 && this.state.totalCount === 0 && !this.state.loading &&
                <InfoCallout text='Your search did not return any results. Try broadening your search criteria.'/>}
                {this.renderWorkOrders()}
                <WorkOrdersSearchDialog callback={this.applySearchFilters} filters={this.props.filters}
                    restricted={this.props.restricted} statuses={this.props.statuses}
                    urgencies={this.props.urgencies}/>
            </div>
        );
    }
}

WorkOrdersPage.propTypes = {
    editable: PropTypes.bool.isRequired,
    filters: PropTypes.object.isRequired,
    recordsPerPage: PropTypes.number.isRequired,
    restricted: PropTypes.bool.isRequired,
    statuses: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
    urgencies: PropTypes.array.isRequired
};