import React from 'react';
import PropTypes from 'prop-types';
import {ROW_SCHEMA} from '../forms/Schema';
import InputField from '../form_fields/InputField';
import DateField from '../form_fields/DateField';

const DateFieldRow = ({row, onChange, withTime}) =>
    <div className='grid-x grid-margin-x align-middle'>
        <div className='shrink cell'>
            <InputField onChange={value => onChange(row, ROW_SCHEMA.NAME, value)}
                placeholder='name' value={row.name}/>
        </div>
        <div className='auto cell'>
            <DateField name={row.name} onChange={value => onChange(row, ROW_SCHEMA.DATE, value)}
                value={row.date} withTime={withTime}/>
        </div>
    </div>;

DateFieldRow.defaultProps = {
    withTime: false
};

DateFieldRow.propTypes = {
    onChange: PropTypes.func.isRequired,
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        date: PropTypes.any
    }).isRequired,
    withTime: PropTypes.bool
};

export default DateFieldRow;