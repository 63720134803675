import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';

const DateField = ({name, onChange, value, withTime}) =>
    <Flatpickr data-enable-time={withTime} name={name}
        onChange={date => onChange && onChange(date[0])}
        options={{
            altInput: true,
            altFormat: withTime ? 'j M Y h:i K' : 'j M Y',
            dateFormat: 'Y-m-d H:i'
        }}
        value={value}/>;

DateField.defaultProps = {
    withTime: false
};

DateField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any,
    withTime: PropTypes.bool
};

export default DateField;