import React from 'react';
import {Bar, ComposedChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import PropTypes from 'prop-types';

const JobsOverTime = props =>
    <ResponsiveContainer height={166} width='100%'>
        <ComposedChart data={props.data} margin={{top: 15}}>
            <XAxis dataKey='date' tick={{fontSize: 16}}/>
            <YAxis allowDecimals={false} tick={{fontSize: 16}} width={40}/>
            <Bar dataKey='count' fill='#a0d3e8' maxBarSize={50}>
                <LabelList dataKey='count' position='top' style={{fontSize: 14}}/>
            </Bar>
            <Tooltip/>
        </ComposedChart>
    </ResponsiveContainer>;

JobsOverTime.propTypes = {
    data: PropTypes.array.isRequired
};

export default JobsOverTime;