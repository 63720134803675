import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import DocumentsDialog from '../dialogs/DocumentsDialog';

/* global Turbolinks:true */
export default class AddDocumentButton extends PureComponent {
    constructor(props) {
        super(props);
        this.dialog = null;
        this.state = {open: false};
    }

    openDialog = () => {
        if (!this.dialog) {
            this.dialog = new Foundation.Reveal($('#documents-dialog'));
        }

        this.dialog.open();
    };

    handleAdd = () => {
        this.setState({open: true}, this.openDialog);
    };

    redirectToNewDocumentForm = templateId => {
        const {recordId, recordType} = this.props;
        this.dialog.close();
        Turbolinks.visit(`/documents/new?template_id=${templateId}&record_id=${recordId}&record_type=${recordType}`);
    };

    render() {
        return (
            <span>
                <a className='clear success button' onClick={this.handleAdd}>
                    <i className='fa fa-plus'/>New Document
                </a>
                {this.state.open && <DocumentsDialog callback={this.redirectToNewDocumentForm}/>}
            </span>
        );
    }
}

AddDocumentButton.propTypes = {
    recordId: PropTypes.number.isRequired,
    recordType: PropTypes.string.isRequired
};