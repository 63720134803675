import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from './UserAvatar';
import UserLabel from './UserLabel';

const UserWidget = ({user, withEmail, withLabel}) =>
    <div className='widget grid-x align-middle avoid-page-break'>
        <div className='shrink cell'>
            <UserAvatar user={user}/>
        </div>
        <div className='auto cell'>
            <div className='tight text-truncate'>
                <div><b>{user.fullName}</b> {withLabel && <UserLabel user={user}/>}</div>
                {withEmail && <small>{user.email}</small>}
            </div>
        </div>
    </div>;

UserWidget.defaultProps = {
    withEmail: false,
    withLabel: false
};

UserWidget.propTypes = {
    user: PropTypes.object.isRequired,
    withEmail: PropTypes.bool,
    withLabel: PropTypes.bool
};

export default UserWidget;