import React, {Component} from 'react';
import JobsOverTime from '../charts/JobsOverTime';
import Rails from '@rails/ujs';
import {AlertCallout} from '../utilities/Callout';
import moment from 'moment';
import FAIcon from '../utilities/FAIcon';

export default class JobsHistogram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: moment().startOf('year'),
            to: moment().endOf('year'),
            error: null,
            jobs: []
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    subtractYear = () => {
        const {from, to} = this.state;
        this.setState({from: from.subtract(1, 'year'), to: to.subtract(1, 'year')}, this.fetchData);
    };

    addYear = () => {
        const {from, to} = this.state;
        this.setState({from: from.add(1, 'year'), to: to.add(1, 'year')}, this.fetchData);
    };

    thisYear = () => {
        this.setState({from: moment().startOf('year'), to: moment().endOf('year')}, this.fetchData);
    };

    fetchData() {
        const {from, to} = this.state;
        Rails.ajax({
            url: `/jobs/query_by_date_range.json?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}`,
            type: 'get',
            success: data => {
                if (this._isMounted) {
                    this.setState({jobs: data});
                }
            },
            error: error => {
                if (this._isMounted) {
                    this.setState({error});
                }
            }
        });
    }

    render() {
        const {error, jobs, from, to} = this.state;
        return (
            <div className='card'>
                <div className='card-divider'>
                    <div className='grid-x grid-margin-x align-middle justify'>
                        <div className='shrink medium-4 cell'>
                            <div className='show-for-large'>
                            Jobs Over Time
                            </div>
                            <div className='hide-for-large'>
                                Jobs
                            </div>
                        </div>
                        <div className='auto cell'>
                            <div className='text-center'>
                                <div className='show-for-large'>
                                    {from.format('MMM D')} - {to.format('ll')}
                                </div>
                                <div className='hide-for-large'>
                                    {to.year()}
                                </div>
                            </div>
                        </div>
                        <div className='shrink large-4 cell text-right'>
                            <div className='button-group margin-0'>
                                <button className='hollow button' onClick={this.subtractYear} type='button'>
                                    <FAIcon icon='arrow-left'/>
                                </button>
                                <button className='hollow button' onClick={this.thisYear} type='button'>
                                    This Year
                                </button>
                                <button className='hollow button' disabled={to.isAfter(moment())}
                                    onClick={this.addYear} type='button'>
                                    <FAIcon icon='arrow-right'/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-section'>
                    {error ? <AlertCallout text={error}/> : <JobsOverTime data={jobs}/>}
                </div>
            </div>
        );
    }
}