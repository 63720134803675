import {ROW_TYPE} from '../forms/Schema';
import Util from '../Util';

/* eslint-disable max-len */
export const EXTERIOR_WORK_ORDER = [
    {
        id: Util.uuid(), title: 'General Information', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Eavestrough', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), name: 'Color/Brand', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Footage', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: '# Footage of Downs', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Exterior Flip Ups', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Gutter Guard', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Fascia', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), name: 'Color/Brand', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Size #1', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Size #2', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Size #3', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Custom', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Soffit', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), name: 'Color/Brand', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Size', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Oversize', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Fixtures/Outlets', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Siding', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), name: 'Type/Brand', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Color', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Amounts', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Tyvek', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Removal Type', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Insultation', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Fixtures/Outlets', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Amount', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Trims', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), name: 'Type/Brand', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Color', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Amount', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Blue Skin/Flash', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Cladding', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), name: 'Color/Brand', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Size/Amount', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Size/Amount', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    }
];

export const ROOFING_WORK_ORDER = [
    {
        id: Util.uuid(), title: 'Delivery Information', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), name: 'Received by', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Delivery date/time', date: '', type: ROW_TYPE.DATETIME_FIELD},
            {id: Util.uuid(), name: 'Delivery details', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Truck', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), name: 'Building', text: '', type: ROW_TYPE.CUSTOMER_OPTION},
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Materials Ordered from Supplier', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Pick Up from CWR Shop', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Materials Returned', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK}
        ]
    },
    {
        id: Util.uuid(), title: 'Job Notes', type: ROW_TYPE.SECTION, rows: [
            {id: Util.uuid(), text: '', type: ROW_TYPE.TEXT_BLOCK},
            {id: Util.uuid(), text: 'Mandatory requirements: Count ALL material and report shortages immediately. Tar tab ALL exposed nails, proper application of roofing cement at any potential problem areas (chimneys, walls, etc.) tripolymer silicone must be used around b-vent furnace chimneys. Ice shield used @ all eaves, valleys, walls & projections. Pictures MUST be taken of any extras or repairs (before & after.)', type: ROW_TYPE.TEXT_BLOCK}
        ]
    }
];