import React, {PureComponent} from 'react';
import Util from '../Util';
import $ from 'jquery';
import {ROW_TYPE} from '../forms/Schema';
import FormSection from '../forms/FormSection';
import Rails from '@rails/ujs';
import PropTypes from 'prop-types';
import {
    EXTERIOR_ESTIMATE,
    ROOFING_ESTIMATE,
    SERVICE_WORK_ESTIMATE
} from './InitialState';
import {InfoCallout} from '../utilities/Callout';
import MoneyInput from '../form_fields/MoneyInput';
import InputField from '../form_fields/InputField';
import NotCompatibleWithQuickBooksMessage from '../utilities/NotCompatibleWithQuickBooksMessage';

/* global Turbolinks:true */
export default class Form extends PureComponent {
    static getInitialState(category) {
        switch (category) {
        case 'Exterior':
            return Util.clone(EXTERIOR_ESTIMATE);
        case 'Roofing':
            return Util.clone(ROOFING_ESTIMATE);
        case 'Service Work/Repair':
            return Util.clone(SERVICE_WORK_ESTIMATE);
        default:
            return null;
        }
    }

    static renderTally = (name, amount) =>
        <div className='grid-x align-bottom align-right'>
            <div className='shrink cell'>
                <p className='money-header'>{name}:</p>
            </div>
            <div className='auto cell text-right'>
                <p className='money-amount'>{Util.toCurrency(amount)}</p>
            </div>
        </div>;

    constructor(props) {
        super(props);

        this.state = {
            title: '',
            sections: Form.getInitialState(this.props.category),
            gst: 0.00,
            grandTotal: 0.00,
            depositRequired: 0.00,
            amountPaid: 0.00,
            amountRefunded: 0.00,
            balance: 0.00,
            reacceptanceRequired: 0,
            invoice: false,
            total: 0.00,
            isQuickBooksCompatible: true
        };

        if (props.estimate) {
            this.state = {
                title: props.estimate.title || '',
                sections: props.estimate.contents,
                gst: props.estimate.gst,
                grandTotal: props.estimate.grandTotal,
                depositRequired: props.estimate.depositRequired,
                amountPaid: props.estimate.amountPaid,
                amountRefunded: props.estimate.amountRefunded,
                balance: props.estimate.grandTotal - props.estimate.amountPaid,
                reacceptanceRequired: props.estimate.reacceptanceRequired || 0,
                invoice: props.estimate.invoice || false,
                total: props.estimate.total
            };
        }

        this.state.isQuickBooksCompatible = this.validateQuickBooksCompatibility(this.state.sections);
    }

    validateQuickBooksCompatibility = sections => {
        for (const section of sections) {
            for (const row of section.rows) {
                if (row.type === ROW_TYPE.MONEY_OPTIONAL) {
                    if (!this.props.quickbooksServices.includes(row.quickbooksService)) {
                        return false;
                    }

                    if (!this.props.quickbooksClasses.includes(row.quickbooksClass)) {
                        return false;
                    }
                }
            }
        }

        return true;
    };

    createOrUpdateEstimate = () => { // eslint-disable-line max-statements
        const {category, estimate, jobId} = this.props;
        const {
            title,
            sections,
            total,
            gst,
            grandTotal,
            depositRequired,
            reacceptanceRequired,
            invoice
        } = this.state;
        const data = new FormData();
        data.append('estimate[category]', category);
        data.append('estimate[title]', title);
        data.append('estimate[contents]', JSON.stringify(sections));
        data.append('estimate[total]', total);
        data.append('estimate[gst]', gst);
        data.append('estimate[grand_total]', grandTotal);
        data.append('estimate[deposit_required]', depositRequired);
        data.append('estimate[reacceptance_required]', reacceptanceRequired);
        data.append('estimate[invoice]', invoice);

        if (estimate) {
            Rails.ajax({
                url: `/estimates/${estimate.id}`,
                type: 'put',
                dataType: 'json',
                data
            });
        }
        else {
            Rails.ajax({
                url: `/jobs/${jobId}/estimates`,
                type: 'post',
                dataType: 'json',
                data
            });
        }
    };

    addObject = (list, type, index = null) => {
        let row;

        switch (type) {
        case ROW_TYPE.CUSTOMER_OPTION:
            row = {id: Util.uuid(), name: '', text: '', type};
            break;
        case ROW_TYPE.DATE_FIELD:
            row = {id: Util.uuid(), name: '', date: '', type};
            break;
        case ROW_TYPE.DATETIME_FIELD:
            row = {id: Util.uuid(), name: '', date: '', type};
            break;
        case ROW_TYPE.MONEY_OPTIONAL:
            row = {id: Util.uuid(), name: '', text: '', amount: 0.0, included: false, type};
            break;
        case ROW_TYPE.SECTION:
            row = {id: Util.uuid(), title: '', rows: [], type};
            break;
        case ROW_TYPE.SERVICE:
            row = {id: Util.uuid(), name: '', included: false, type};
            break;
        case ROW_TYPE.SERVICE_WITH_TEXT:
            row = {id: Util.uuid(), name: '', text: '', included: false, type};
            break;
        case ROW_TYPE.TEXT_BLOCK:
            row = {id: Util.uuid(), text: '', type};
            break;
        default:
            row = null;
        }

        list.splice((index || list.length) + 1, 0, row);
        this.forceUpdate();
    };

    copyObject = (list, index) => {
        this.addObject(list, list[index].type, index);
        this.forceUpdate(() => $('.dropdown').foundation());
    };

    removeObject = (list, index) => {
        list.splice(index, 1);
        this.forceUpdate();
    };

    updateField = (row, field, value) => {
        row[field] = value;
        this.updateSummary();
        this.forceUpdate();
    };

    updateSummary = () => {
        let total = 0;

        for (const section of this.state.sections) {
            for (const row of section.rows) {
                if (Util.defined(row.amount) && row.included) {
                    total += row.amount;
                }
            }
        }

        const gst = total * 0.05;
        const grandTotal = total + gst;
        const depositRequired = this.props.category === 'Service Work/Repair'
            ? this.state.depositRequired : grandTotal * 0.20;
        const balance = grandTotal - this.state.amountPaid;

        this.setState({
            total, gst, grandTotal, depositRequired, balance,
            isQuickBooksCompatible: this.validateQuickBooksCompatibility(this.state.sections)
        });
    };

    renderSummary = () =>
        <div className='estimate-summary'>
            {Form.renderTally('Total', this.state.total)}
            {Form.renderTally('GST', this.state.gst)}
            {Form.renderTally('Grand Total', this.state.grandTotal)}
            <div className='grid-x align-bottom align-right'>
                <div className='auto cell'>
                    <p className='money-header tight'>
                        Deposit (20% - {Util.toCurrency(this.state.grandTotal * 0.2)}):
                        <br/>
                        <small>No deposit = full balance is due</small>
                    </p>
                </div>
                <div className='small-5 medium-3 large-2 cell text-right'>
                    <MoneyInput
                        amount={this.state.depositRequired}
                        callback={value => this.setState({depositRequired: value})}
                        inputClass='money-amount lead'
                        max={Number(this.state.total)}
                        name='deposit_required'
                    />
                </div>
            </div>
            {Form.renderTally('Amount Received', this.state.amountPaid)}
            {this.state.amountRefunded > 0.0 && Form.renderTally('Amount Refunded', this.state.amountRefunded)}
            {Form.renderTally('Balance', this.state.balance)}
        </div>;

    render() {
        const {category, estimate, quickbooksClasses, quickbooksServices} = this.props;
        const {title, sections, isQuickBooksCompatible} = this.state;
        return (
            <div className='estimate'>
                <div className='card'>
                    <div className='card-divider'>
                        Optional Title (max 50 characters)
                    </div>
                    <div className='card-section'>
                        <span className='help-twdwext'/>
                        <InputField
                            maxLength={50}
                            onChange={value => this.setState({title: value})}
                            placeholder='Enter a user-friendly title (OPTIONAL)' value={title}/>

                        <b>Customer will see:</b>
                        <pre><b className='lead info-color'>
                            {title || category} {this.state.invoice ? 'Invoice' : 'Estimate'}
                        </b></pre>
                    </div>
                </div>
                {!isQuickBooksCompatible && <NotCompatibleWithQuickBooksMessage/>}
                {this.state.sections.map((section, index) =>
                    <FormSection
                        addField={this.addObject} copySelf={this.copyObject} destroySelf={this.removeObject}
                        key={section.id} onChange={this.updateField} parentList={sections}
                        quickbooksClasses={quickbooksClasses} quickbooksServices={quickbooksServices}
                        section={section} selfIndex={index}/>)}
                <div className='card'>
                    <div className='card-divider'>
                    Summary
                    </div>
                    <div className='card-section'>
                        {this.renderSummary()}
                    </div>
                </div>
                {estimate && !estimate.invoice && <div className='card'>
                    <div className='card-divider'>
                        Signatures
                    </div>
                    <div className='card-section'>
                        <div className='grid-x align-middle'>
                            <div className='shrink cell'>
                                <div className='padded large switch'>
                                    <input checked={this.state.reacceptanceRequired || 0} className='switch-input'
                                        id='estimate_reacceptance_required' name='estimate[reacceptance_required]'
                                        onChange={e => this.setState({reacceptanceRequired: e.target.checked})}
                                        type='checkbox'/>
                                    <label className='switch-paddle' htmlFor='estimate_reacceptance_required'>
                                        <span className='switch-active'>Yes</span>
                                        <span className='switch-inactive'>No</span>
                                    </label>
                                </div>
                            </div>
                            <div className='auto cell'>
                                <label className='lead' htmlFor='estimate_reacceptance_required'>
                                    <p>Customer is required to re-sign this estimate version</p>
                                </label>
                            </div>
                        </div>
                        <InfoCallout text='Saying yes will prevent final sign off and require another signature.'/>
                    </div>
                </div>}

                <div className='card'>
                    <div className='card-divider'>
                        Invoice Conversion
                    </div>
                    <div className='card-section'>

                        <div className='grid-x align-middle margin-bottom-1'>
                            <div className='shrink cell'>
                                <div className='padded large switch margin-bottom-0'>
                                    <input checked={this.state.invoice} className='switch-input'
                                        id='estimate_invoice' name='estimate[invoice]'
                                        onChange={e => this.setState({invoice: e.target.checked})}
                                        type='checkbox'/>
                                    <label className='switch-paddle' htmlFor='estimate_invoice'>
                                        <span className='switch-active'>Yes</span>
                                        <span className='switch-inactive'>No</span>
                                    </label>
                                </div>
                            </div>
                            <div className='auto cell'>
                                <label className='lead' htmlFor='estimate_invoice'>
                                    Convert this estimate to invoice
                                </label>
                            </div>
                        </div>
                        <InfoCallout>
                            <ul className='margin-bottom-0'>
                                <li>
                                    Invoices do not require an acceptance signature &mdash; only the final sign off
                                    signature.
                                </li>
                                <li>
                                    Invoices require the full balance to be paid regardless of the deposit amount.
                                </li>
                            </ul>
                        </InfoCallout>
                    </div>
                </div>
                {!isQuickBooksCompatible && <NotCompatibleWithQuickBooksMessage/>}
                <div className='grid-x grid-margin-x'>
                    <div className='shrink cell'>
                        <a className='large secondary button'
                            onClick={() => Turbolinks.visit(estimate
                                ? `/estimates/${estimate.id}` : `/jobs/${this.props.jobId}`)}>
                            <i className='fa fa-times'/>Cancel
                        </a>
                    </div>
                    <div className='auto cell'>
                        <a className='large expanded success button'
                            onClick={this.createOrUpdateEstimate}>
                            {estimate ? 'Update' : 'Create'} {this.state.invoice ? 'Invoice' : 'Estimate'}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

Form.propTypes = {
    category: PropTypes.string.isRequired,
    estimate: PropTypes.object,
    jobId: PropTypes.number.isRequired,
    quickbooksClasses: PropTypes.array.isRequired,
    quickbooksServices: PropTypes.array.isRequired
};