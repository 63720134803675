import React, {PureComponent} from 'react';
import DialogCloseButton from '../dialog_buttons/DialogCloseButton';
import Rails from '@rails/ujs';
import {notify} from 'react-notify-toast';
import $ from 'jquery';
import PropTypes from 'prop-types';
import SignaturePad from 'signature_pad';

export default class CustomerSignatureDialog extends PureComponent {
    static dataURLtoBlob = dataURL => {
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});
    };

    constructor(props) {
        super(props);
        this.canvas = null;
        this.pad = null;
        this.state = {fullName: ''};
    }

    componentDidMount() {
        $(window).resize(this.resizeCanvas);
        $(document).on('open.zf.reveal', () => setTimeout(this.resizeCanvas, 100));
        $(document).on('closed.zf.reveal', () => this.pad.clear());
        $('[data-react-class="dialogs/CustomerSignatureDialog"]').removeAttr('data-react-class');
        this.canvas = document.querySelector('canvas');
        this.pad = new SignaturePad(this.canvas);
    }

    componentWillUnmount() {
        $(document).off('open.zf.reveal', this.resizeCanvas);
        $(document).off('closed.zf.reveal', this.resizeCanvas);
        $(window).off('resize', this.resizeCanvas);
    }

    resizeCanvas = () => {
        /*
         * When zoomed out to less than 100%, for some very strange reason,
         * some browsers report devicePixelRatio as less than 1
         * and only part of the canvas is cleared then.
         */
        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        // This part causes the canvas to be cleared
        this.canvas.width = this.canvas.offsetWidth * ratio;
        this.canvas.height = this.canvas.offsetHeight * ratio;
        this.canvas.getContext('2d').scale(ratio, ratio);

        /*
         * This library does not listen for canvas changes, so after the canvas is automatically
         * cleared by the browser, SignaturePad#isEmpty might still return false, even though the
         * canvas looks empty, because the internal data of this library wasn't cleared. To make sure
         * that the state of this library is consistent with visual state of the canvas, you
         * have to clear it manually.
         */
        this.pad.clear();
    };

    sign = e => {
        e.preventDefault();

        if (this.state.fullName.trim().length === 0) {
            notify.show('Please provide a name first.', 'error');
        }
        else if (this.pad.isEmpty()) {
            notify.show('Please provide a signature first.', 'error');
        }
        else {
            const blob = CustomerSignatureDialog.dataURLtoBlob(this.pad.toDataURL('image/svg+xml'));
            const data = new FormData();
            data.append('signature[full_name]', this.state.fullName);
            data.append('signature[image]', blob, 'signature.svg');
            Rails.ajax({
                url: `/estimates/${this.props.estimateId}/sign_accept`,
                type: 'post',
                data,
                error: error => {
                    notify.show(error, 'error');
                }
            });
        }
    };

    render() {
        return (
            <div className='reveal' data-reveal id='customer-signature-dialog'>
                <h2>Sign This Document</h2>

                <form onSubmit={this.sign}>
                    <label htmlFor='full-name'>Full name:</label>
                    <input id='full-name' onChange={e => this.setState({fullName: e.target.value})}
                        required type='text'/>

                    <canvas className='signature-canvas'/>
                    <p className='help-text'>Draw your signatures on the canvas.</p>

                    <div className='grid-x align-middle'>
                        <div className='shrink cell'>
                            <input className='large-checkbox margin-right-1' id='terms' required type='checkbox'/>
                        </div>
                        <div className='auto cell'>
                            <label className='middle' htmlFor='terms'>
                                I have read and accept the <a data-open='terms-conditions'><b>Terms &
                                Conditions</b></a>.</label>
                        </div>
                    </div>

                    <div className='grid-x grid-margin-x'>
                        <div className='shrink cell'>
                            <a className='margin-bottom-0 secondary button' data-close=''>
                                <i className='fa fa-times'/>Cancel
                            </a>
                        </div>
                        <div className='shrink cell'>
                            <a className='margin-bottom-0 info button' onClick={() => this.pad.clear()}>
                                <i className='fa fa-eraser'/>Clear
                            </a>
                        </div>
                        <div className='auto cell'>
                            <input className='expanded margin-bottom-0 success button'
                                type='submit' value='Sign & Save'/>
                        </div>
                    </div>
                </form>

                <DialogCloseButton/>
            </div>
        );
    }
}

CustomerSignatureDialog.propTypes = {
    estimateId: PropTypes.string.isRequired
};