import React from 'react';
import PropTypes from 'prop-types';
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';

const COLORS = {
    Low: '#92cd18',
    Medium: '#f3b911',
    High: '#dc3545'
};

const CustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);

    return (
        <text dominantBaseline='central' fill='white' textAnchor={x > cx ? 'start' : 'end'} x={x} y={y}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

CustomizedLabel.propTypes = {
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    innerRadius: PropTypes.number.isRequired,
    midAngle: PropTypes.number.isRequired,
    outerRadius: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
};

const JobsByUrgency = ({data}) =>
    <ResponsiveContainer height={300} width='100%'>
        <PieChart margin={{top: 0, right: 0, bottom: 0, left: 0}}>
            <Pie data={data} dataKey='count' innerRadius={15} label={CustomizedLabel} labelLine={false}>
                {data.map(entry => <Cell fill={COLORS[entry.name]} key={entry}/>)}
            </Pie>
            <Tooltip/>
        </PieChart>
    </ResponsiveContainer>;

JobsByUrgency.propTypes = {
    data: PropTypes.array.isRequired
};

export default JobsByUrgency;