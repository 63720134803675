import React, {PureComponent} from 'react';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import {notify} from 'react-notify-toast';
import Rails from '@rails/ujs';
import Mark from 'mark.js/dist/mark.es6';
import PhoneNumber from '../PhoneNumber';
import Address from '../Address';
import DialogCloseButton from '../dialog_buttons/DialogCloseButton';

/* global Turbolinks:true */
export default class CustomerLookupDialog extends PureComponent {
    static renderCustomer = customer =>
        <div className='tr' key={customer.id}
            onClick={() => Turbolinks.visit(`/jobs/new?customer_id=${customer.id}`)}>
            <div className='grid-x grid-margin-x align-middle small-up-2'>
                <div className='cell'>
                    <div>{customer.fullName}</div>
                    {customer.phones.length > 0 && <PhoneNumber phone={customer.phones[0]}/>}
                </div>
                <div className='cell'>
                    {customer.address && <Address address={customer.address}/>}
                </div>
            </div>
        </div>;

    state = {
        customers: [],
        query: ''
    };

    componentDidMount() {
        this.instance = new Mark(document.querySelector('#customers'));
        this.dialog = new Foundation.Reveal($('#customer-lookup'));
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        const {query} = this.state;

        if (prevState.query !== query) {
            if (!this.isSearchEmpty()) {
                this.findCustomers();
            }
        }

        this.instance.unmark();
        this.instance.mark(query, {ignorePunctuation: ['()- ']});
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.dialog) {
            this.dialog.close();
        }
    }

    isSearchEmpty = () => this.state.query.trim().length === 0;

    findCustomers = () => {
        Rails.ajax({
            url: `/customers/search.json?query=${this.state.query}`,
            type: 'get',
            success: data => {
                if (this._isMounted) {
                    this.setState({customers: data.array});
                }
            },
            error: error => {
                if (this._isMounted) {
                    notify.show(error, 'error');
                    this.setState({customers: []});
                }
            }
        });
    };

    render() {
        return (
            <div>
                <div className='reveal' id='customer-lookup'>
                    <DialogCloseButton/>
                    <p className='h1'>Customer Lookup</p>
                    <div className='input-group'>
                        <label className='input-group-label' htmlFor='query'>
                            <i className='fa fa-search'/>
                        </label>
                        <input className='input-group-field' id='query' name='text'
                            onChange={e => this.setState({query: e.target.value})}
                            placeholder='Start typing to look up customer...' type='search'/>
                    </div>
                    <div className='table margin-bottom-1' id='customers'>
                        {!this.isSearchEmpty() && this.state.customers.map(CustomerLookupDialog.renderCustomer)}
                    </div>
                    <b>{this.isSearchEmpty() ? 'Want to skip the search?' : 'Don\'t see what you\'re looking for?'}</b>
                    <a className='margin-left-1 clear button' href='/jobs/new'>Create New Customer</a>
                </div>
            </div>
        );
    }
}