import React, {PureComponent} from 'react';

export default class Spinner extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {time: 0};
        this.timer = null;
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({time: (this.state.time + 1) % 3});
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    renderEllipses = () => {
        let ellipses = '';

        for (let i = 0; i <= this.state.time; i++) {
            ellipses += ' .';
        }

        return ellipses;
    };

    render() {
        return (
            <div className='grid-x align-center'>
                <div className='shrink cell'>
                    <div className='primary margin-bottom-0 callout text-center'>
                        <i className='fa fa-spinner fa-pulse'/>Loading data{this.renderEllipses()}
                    </div>
                </div>
            </div>
        );
    }
}