import React from 'react';
import PropTypes from 'prop-types';
import {ROW_SCHEMA} from '../forms/Schema';
import InputField from '../form_fields/InputField';

const CustomerOptionRow = ({row, onChange}) =>
    <div className='grid-x grid-margin-x align-middle'>
        <div className='shrink cell'>
            <InputField onChange={value => onChange(row, ROW_SCHEMA.NAME, value)}
                placeholder='name' value={row.name}/>
        </div>
        <div className='auto cell'>
            <InputField onChange={value => onChange(row, ROW_SCHEMA.TEXT, value)}
                placeholder='option' value={row.text}/>
        </div>
    </div>;

CustomerOptionRow.propTypes = {
    onChange: PropTypes.func.isRequired,
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        text: PropTypes.string
    }).isRequired
};

export default CustomerOptionRow;