import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Rails from '@rails/ujs';

export default class UserRoles extends PureComponent {
    state = {myRoles: this.props.user.roles.slice(0), loading: false};

    addRole = role => {
        const myRoles = this.state.myRoles;
        myRoles.push(role);
        this.setState({myRoles});
        this.forceUpdate();
    };

    removeRole = role => {
        const myRoles = this.state.myRoles;
        myRoles.splice(myRoles.indexOf(role), 1);
        this.setState({myRoles});
        this.forceUpdate();
    };

    saveRoles = () => {
        this.setState({loading: true});
        const data = new FormData();
        data.append('roles', JSON.stringify(this.state.myRoles));
        Rails.ajax({
            url: `/users/${this.props.user.id}/update_roles`,
            type: 'put',
            dataType: 'json',
            data
        });
    };

    rolesEdited = () => {
        const rolesBefore = this.props.user.roles.sort();
        const rolesAfter = this.state.myRoles.sort();
        let edited = rolesBefore.length !== rolesAfter.length;

        for (let i = 0; i < rolesBefore.length && !edited; i++) {
            if (rolesBefore[i] !== rolesAfter[i]) {
                edited = true;
            }
        }

        return edited;
    };

    renderAllRoles = () =>
        this.props.availRoles.map(role => {
            if (this.state.myRoles.indexOf(role) >= 0) {
                return <span className='success label' key={role} onClick={() => this.removeRole(role)}>
                    {role}
                </span>;
            }

            return <span className='secondary label' key={role} onClick={() => this.addRole(role)}>
                {role}
            </span>;
        });

    render() {
        return (
            <div>
                <div className='grid-x align-middle'>
                    <div className='auto cell'>
                        {this.renderAllRoles()}
                    </div>
                    {this.rolesEdited() && <div className='shrink cell'>
                        {this.state.loading
                            ? <small><i className='fa fa-spinner fa-pulse'/>Saving</small>
                            : <a className='small clear margin-bottom-0 success button' onClick={this.saveRoles}>
                                <i className='fa fa-check'/>Save
                            </a>
                        }
                    </div>}
                </div>
                <small className='subheader'><i className='fa fa-info-circle'/>Click on a role to toggle it.</small>
            </div>
        );
    }
}

UserRoles.propTypes = {
    availRoles: PropTypes.array.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        roles: PropTypes.array.isRequired
    }).isRequired
};