import React from 'react';
import PropTypes from 'prop-types';

const UserAvatar = ({user}) =>
    <span className='avatar'>
        {user.imageUrl ? <img alt={user.initials} src={user.imageUrl}/> : <small>{user.initials}</small>}
    </span>;

UserAvatar.propTypes = {
    user: PropTypes.object.isRequired
};

export default UserAvatar;