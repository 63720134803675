export default class Util {
    // eslint-disable-next-line no-undefined
    static defined = object => typeof object !== 'undefined' && object !== null;

    static clone = object => JSON.parse(JSON.stringify(object));

    static uuid = () => Math.random().toString(36).slice(2);

    static toDecimal = amount => {
        let decimal = 0.00;

        if (Util.defined(amount)) {
            const trimmed = amount.toString().trim();

            if (trimmed.length > 0) {
                const numeric = amount.toString().replace(/\D/g, '');

                if (!isNaN(numeric)) {
                    decimal = Number(numeric) / 100.0;
                }
            }
        }

        return decimal;
    };

    static formatMoney = amount => {
        let formatted = '';

        const rounded = isNaN(amount) ? amount : Math.round(amount);

        if (amount) {
            const trimmed = rounded.toString().trim();

            if (trimmed.length > 0) {
                const numeric = rounded.toString().replace(/\D/g, '');

                if (!isNaN(numeric)) {
                    // eslint-disable-next-line no-undefined
                    formatted = `$${(Number(numeric) / 100.0).toLocaleString(undefined, {minimumFractionDigits: 2})}`;
                }
            }
        }

        return formatted;
    };

    static toCurrency = amount => // eslint-disable-next-line no-undefined
        `$${Number(amount).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`;
}