import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Util from '../Util';

export default class MoneyInput extends PureComponent {
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.amount !== prevState.amount) {
            return {
                amount: Util.toDecimal(nextProps.amount),
                formattedAmount: Util.formatMoney(nextProps.amount * 100)
            };
        }

        return prevState;
    }

    constructor(props) {
        super(props);
        this.state = {
            amount: Util.toDecimal(props.amount),
            formattedAmount: Util.formatMoney(props.amount * 100)
        };
    }

    handleChange = e => {
        const value = e.target.value;
        let rawValue = Util.toDecimal(value);
        let formatted = Util.formatMoney(value);

        if (this.props.max && rawValue > this.props.max) {
            rawValue = Util.toDecimal(this.props.max * 100);
            formatted = Util.formatMoney(this.props.max * 100);
        }

        this.setState({formattedAmount: formatted, amount: rawValue}, () => {
            if (this.props.callback) {
                this.props.callback(rawValue);
            }
        });
    };

    render() {
        return (
            <>
                <input
                    type='hidden'
                    value={this.state.amount}
                />
                <input
                    className={`money-input ${this.props.inputClass || ''}`.trim()}
                    id={this.props.name}
                    inputMode='numeric'
                    name={this.props.name}
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    required={this.props.required}
                    type='text'
                    value={Util.formatMoney(this.state.formattedAmount)}
                />
            </>
        );
    }
}

MoneyInput.defaultProps = {
    placeholder: '0.00',
    required: false
};

MoneyInput.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    callback: PropTypes.func,
    inputClass: PropTypes.string,
    max: PropTypes.number,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool
};