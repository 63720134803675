import React from 'react';
import PropTypes from 'prop-types';
import {ROW_SCHEMA} from './Schema';

const TextRow = ({row, onChange}) =>
    <textarea onChange={e => onChange(row, ROW_SCHEMA.TEXT, e.target.value)}
        placeholder='comments / description / details' value={row.text}/>;

TextRow.propTypes = {
    onChange: PropTypes.func.isRequired,
    row: PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string
    }).isRequired
};

export default TextRow;