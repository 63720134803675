import React from 'react';
import PropTypes from 'prop-types';

const Address = ({address}) =>
    <div>
        <div className='text-truncate'>{address.line1}</div>
        <div className='text-truncate'>{address.line2}</div>
        <div className='text-truncate'>{address.city}, {address.province} {address.postalCode}</div>
    </div>;

Address.propTypes = {
    address: PropTypes.shape({
        line1: PropTypes.string.isRequired,
        line2: PropTypes.string,
        city: PropTypes.string.isRequired,
        province: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired
    }).isRequired
};

export default Address;