import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Util from '../Util';

export default class CheckBox extends PureComponent {
    constructor(props) {
        super(props);
        this.id = props.id || Util.uuid();
        this.state = {checked: props.checked};
    }

    handleChange = e => {
        const checked = e.target.checked;
        this.setState({checked}, () => {
            if (this.props.callback) {
                this.props.callback(checked);
            }
        });
    };

    render() {
        return (
            <div className='grid-x align-center-middle'>
                <div className='shrink cell'>
                    {!this.props.readOnly && <div className={`${this.props.label ? 'padded ' : ''} switch`}>
                        <input checked={this.state.checked} className='switch-input'
                            disabled={this.props.readOnly} id={this.id}
                            name={this.props.name} onChange={this.handleChange} type='checkbox'/>
                        <label className='switch-paddle' htmlFor={this.id}>
                            <span className='switch-active'>Yes</span>
                            <span className='switch-inactive'>No</span>
                        </label>
                    </div>}
                    {this.props.readOnly && (this.state.checked
                        ? <p><i className='fa fa-check'/>Yes</p>
                        : <p><i className='fa fa-times'/>No</p>)}
                </div>
                {this.props.label && <div className='auto cell text-left'>
                    <label className='font-normal middle' htmlFor={this.id}>{this.props.label}</label>
                </div>}
            </div>
        );
    }
}

CheckBox.defaultProps = {
    checked: false,
    readOnly: false
};

CheckBox.propTypes = {
    callback: PropTypes.func,
    checked: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    readOnly: PropTypes.bool
};