import React, {Component} from 'react';
import Util from '../Util';
import {ROW_SCHEMA, ROW_TYPE_LIST} from './Schema';
import PropTypes from 'prop-types';
import AddRemoveButtons from './AddRemoveButtons';
import InputField from '../form_fields/InputField';
import FAIcon from '../utilities/FAIcon';
import FormRow from './FormRow';
import Form from '../estimates/Form';

class FormSection extends Component {
    static getDerivedStateFromProps(props) {
        return {total: FormSection.getTotal(props.section)};
    }

    static getTotal(section) {
        let total = null;

        for (const row of section.rows) {
            if (Util.defined(row[ROW_SCHEMA.AMOUNT])) {
                total = total || 0.00;
            }

            if (row[ROW_SCHEMA.INCLUDED] && Util.defined(row[ROW_SCHEMA.AMOUNT])) {
                total += Number(row[ROW_SCHEMA.AMOUNT]);
            }
        }

        if (total === null) {
            delete section.subtotal;
        }
        else {
            section.subtotal = total;
        }

        return total;
    }

    state = {total: null};

    renderNewFieldButton = () => {
        const {addField, section} = this.props;
        return (
            <ul className='dropdown menu' data-click-open data-disable-hover data-dropdown-menu>
                <li><a className='clear button'><FAIcon icon='plus'/>Add field</a>
                    <ul className='menu is-dropdown-submenu'>
                        {ROW_TYPE_LIST.map(type =>
                            <li className='text-nowrap' key={type.id}>
                                <a onClick={() => addField(section.rows, type.id)}>
                                    {type.text}
                                </a>
                            </li>)}
                    </ul>
                </li>
            </ul>);
    };

    render() {
        const {
            copySelf, destroySelf, onChange, parentList, quickbooksClasses, quickbooksServices, section, selfIndex
        } = this.props;
        const {total} = this.state;

        return (
            <div className='card'>
                <div className='card-divider'>
                    <div className='grid-x align-middle'>
                        <div className='shrink cell'>
                            <AddRemoveButtons className='margin-0'
                                insert={() => copySelf(parentList, selfIndex)}
                                remove={parentList.length === 1 ? null
                                    : () => destroySelf(parentList, selfIndex)}/>
                        </div>
                        <div className='auto cell'>
                            <InputField className='margin-0 font-bold'
                                onChange={value => onChange(section, ROW_SCHEMA.TITLE, value)}
                                placeholder='section title' value={section.title}/>
                        </div>
                    </div>
                </div>
                <div className='card-section padding-0'>
                    {section.rows.map((row, index) =>
                        <FormRow
                            copySelf={copySelf} destroySelf={destroySelf}
                            key={row.id} onChange={onChange} parentList={section.rows}
                            quickbooksClasses={quickbooksClasses} quickbooksServices={quickbooksServices}
                            row={row} selfIndex={index}/>)}
                </div>
                <div className='card-divider'>
                    <div className='grid-x grid-margin-x align-justify padding-top-1'>
                        <div className='shrink cell'>
                            {this.renderNewFieldButton()}
                        </div>
                        {total !== null && <div className='medium-4 cell'>
                            {Form.renderTally('Subtotal', total)}
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

FormSection.propTypes = {
    addField: PropTypes.func.isRequired,
    copySelf: PropTypes.func.isRequired,
    destroySelf: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    parentList: PropTypes.array.isRequired,
    quickbooksClasses: PropTypes.array,
    quickbooksServices: PropTypes.array,
    section: PropTypes.shape({
        title: PropTypes.string.isRequired,
        rows: PropTypes.array.isRequired
    }).isRequired,
    selfIndex: PropTypes.number.isRequired
};

export default FormSection;