import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class SimpleSelect extends PureComponent {
    renderOptions = () => this.props.items.map(item => {
        if (typeof item === 'object') {
            return <option key={item.key} value={item.key}>{item.value}</option>;
        }

        return <option key={item} value={item}>{item}</option>;
    });

    handleChange = e => {
        this.props.onChange(e.target.value);
    };

    render() {
        const {value, prompt, style} = this.props;
        const selectStyle = value ? style : {...style, color: 'darkgrey'};

        return (
            <div className='grid-x grid-margin-x'>
                <div className='auto cell'>
                    <div className={this.props.errors && this.props.errors.length ? 'field_with_errors' : ''}>
                        <select onChange={this.handleChange} style={selectStyle} value={value}>
                            <option>{prompt || 'Select an option'}</option>
                            {this.renderOptions()}
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

SimpleSelect.defaultProps = {
    style: {}
};

SimpleSelect.propTypes = {
    errors: PropTypes.array,
    items: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    prompt: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.string
};