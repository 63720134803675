import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import DialogCloseButton from '../dialog_buttons/DialogCloseButton';
import DateField from '../form_fields/DateField';
import Rails from '@rails/ujs';
import {notify} from 'react-notify-toast';

export default class WorkOrdersSearchDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            from: props.filters.from,
            to: props.filters.to,
            assignees: props.filters.assignees || [],
            status: props.filters.status || [],
            urgency: props.filters.urgency || [],
            users: []
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        Rails.ajax({
            url: '/users/active.json',
            type: 'get',
            success: users => {
                if (this._isMounted) {
                    this.setState({users});
                }
            },
            error: error => {
                if (this._isMounted) {
                    notify.show(error, 'error');
                    this.setState({users: []});
                }
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateAssignees = e => {
        const assignees = [];

        for (const option of e.target.options) {
            if (option.selected) {
                assignees.push(option.value);
            }
        }

        this.setState({assignees});
    };

    updateStatuses = e => {
        const status = this.state.status;

        if (e.target.checked) {
            status.push(e.target.value);
        }
        else {
            const index = status.indexOf(e.target.value);

            if (index !== -1) {
                status.splice(index, 1);
            }
        }

        this.setState({status});
        this.forceUpdate();
    };

    updateUrgencies = e => {
        const urgency = this.state.urgency;

        if (e.target.checked) {
            urgency.push(e.target.value);
        }
        else {
            const index = urgency.indexOf(e.target.value);

            if (index !== -1) {
                urgency.splice(index, 1);
            }
        }

        this.setState({urgency});
        this.forceUpdate();
    };

    clearFilters = () => {
        const filters = {from: null, to: null, assignees: [], status: [], urgency: []};
        this.setState({...filters}, () => this.props.callback({}));
    };

    handleSubmit = e => {
        const {from, to, assignees, status, urgency} = this.state;
        this.props.callback({from, to, assignees, status, urgency});
        e.preventDefault();
    };

    renderAssignees = () => {
        const {assignees, users} = this.state;
        return <select multiple onChange={this.updateAssignees} value={assignees}>
            <option value=''>- Unassigned -</option>
            {users.map(user => <option key={user.id} value={user.id}>{user.fullName}</option>)}
        </select>;
    };

    renderUrgencies = () => this.props.urgencies.map(urgency =>
        <span key={urgency}>
            <input checked={this.state.urgency.includes(urgency)}
                id={`urgency-${urgency}`} name='urgency[]' onChange={this.updateUrgencies}
                type='checkbox' value={urgency}/>
            <label htmlFor={`urgency-${urgency}`}>{urgency}</label>
        </span>);

    renderStatuses = () => this.props.statuses.map(status =>
        <div className='cell' key={status}>
            <input checked={this.state.status.includes(status)} className='margin-bottom-0'
                id={`status-${status}`} name='status[]' onChange={this.updateStatuses}
                type='checkbox' value={status}/>
            <label htmlFor={`status-${status}`}>{status}</label>
        </div>);

    render() {
        return (
            <div className='reveal' id='search-filters-dialog'>
                <h2>Enable Search Filters</h2>
                <form id='search-form' onSubmit={this.handleSubmit}>
                    <label><b>Date Range</b></label>
                    <div className='grid-x grid-margin-x'>
                        <div className='auto cell'>
                            <DateField name='from' onChange={from => this.setState({from})}
                                value={this.state.from}/>
                        </div>
                        <div className='shrink cell'>
                            <label className='middle lead'>to</label>
                        </div>
                        <div className='auto cell'>
                            <DateField name='to' onChange={to => this.setState({to})}
                                value={this.state.to}/>
                        </div>
                    </div>

                    {!this.props.restricted && <label><b>Assigned to (choose one or more staff)</b></label>}
                    {!this.props.restricted && this.renderAssignees()}

                    <label><b>Urgency</b></label>
                    {this.renderUrgencies()}

                    <label><b>Status</b></label>
                    <div className='grid-x grid-margin-x small-up-2 medium-up-3'>
                        {this.renderStatuses()}
                    </div>
                    <br/>
                    <div className='grid-x grid-margin-x align-justify'>
                        <div className='shrink cell'>
                            <button className='margin-bottom-0 secondary button'
                                data-close='' onClick={this.clearFilters} type='button'>
                                <i className='fa fa-times'/>Clear Filters
                            </button>
                        </div>
                        <div className='auto cell'>
                            <button className='expanded margin-bottom-0 success button'
                                data-close='' type='submit'>
                                <i className='fa fa-filter'/>Apply Filters
                            </button>
                        </div>
                    </div>
                </form>

                <DialogCloseButton/>
            </div>
        );
    }
}

WorkOrdersSearchDialog.propTypes = {
    callback: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    restricted: PropTypes.bool.isRequired,
    statuses: PropTypes.array.isRequired,
    urgencies: PropTypes.array.isRequired
};