import React, {PureComponent} from 'react';

export default class EmailAttachmentUploader extends PureComponent {
    state = {files: [null]};

    saveFilename = e => {
        if (e.target.value.length) {
            this.state.files.push(e.target.value);
            this.setState({files: this.state.files});
            this.forceUpdate();
        }
    };

    deleteFile = index => {
        this.state.files.splice(index, 1);
        this.setState({files: this.state.files});
        this.forceUpdate();
    };

    renderFile = (filename, index) =>
        <div className='grid-x grid-margin-x align-middle' key={filename}>
            <div className='auto cell'>
                <input
                    className={index === this.state.files.length - 1 ? 'show-for-sr' : 'margin-0 untouchable'}
                    id={index === this.state.files.length - 1 ? 'attachment' : ''}
                    key={filename} name='email[attachments][]'
                    onChange={this.saveFilename} type='file'/>
            </div>
            {index !== this.state.files.length - 1 && <div className='shrink cell'>
                <a className='clear margin-0 alert button' onClick={() => this.deleteFile(index)}>
                    <i className='fa fa-trash-o'/>Delete
                </a>
            </div>}
        </div>;

    render() {
        return (
            <div className='margin-bottom-1'>
                <label className='clear info margin-0 button' htmlFor='attachment'>
                    <i className='fa fa-upload'/>Upload Attachment
                </label>
                {this.state.files.map(this.renderFile)}
            </div>
        );
    }
}

EmailAttachmentUploader.propTypes = {};