import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Foundation from 'foundation-sites';
import $ from 'jquery';
import SignatureDialog from '../dialogs/SignatureDialog';

export default class AddSignatureButton extends PureComponent {
    constructor(props) {
        super(props);
        this.dialog = null;
        this.state = {open: false};
    }

    openDialog = () => {
        if (!this.dialog) {
            this.dialog = new Foundation.Reveal($('#signature-dialog'));
        }

        this.dialog.open();
    };

    handleAdd = () => {
        this.setState({open: true}, this.openDialog);
    };

    render() {
        return (
            <span>
                {this.props.float
                    ? <a className='float success button' onClick={this.handleAdd}>
                        Sign
                    </a>
                    : <a className='clear success button' onClick={this.handleAdd}>
                        <i className='fa fa-plus'/>Add Signature
                    </a>}
                {this.state.open && <SignatureDialog callback={() => this.dialog.close()}
                    recordId={this.props.recordId}
                    recordType={this.props.recordType}/>}
            </span>
        );
    }
}

AddSignatureButton.defaultProps = {
    float: false
};

AddSignatureButton.propTypes = {
    float: PropTypes.bool,
    recordId: PropTypes.number.isRequired,
    recordType: PropTypes.string.isRequired
};