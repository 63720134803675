import {loadStripeTerminal} from '@stripe/terminal-js';
import Rails from '@rails/ujs';

const fetchConnectionToken = () => new Promise((resolve, reject) => {
    Rails.ajax({
        url: '/stripe/connection_token',
        type: 'post',
        async: false,
        success: data => {
            resolve(data.secret);
        },
        error: error => {
            reject(error);
        }
    });
});

const unexpectedDisconnect = () => {
    /*
     * In this function, your app should notify the user that the reader disconnected.
     * You can also include a way to attempt to reconnect to a reader.
     */
};

const createInstance = async() => {
    const terminal = await loadStripeTerminal();
    return terminal.create({
        onFetchConnectionToken: fetchConnectionToken,
        onUnexpectedReaderDisconnect: unexpectedDisconnect
    });
};

const discoverStripeReaders = terminal => new Promise((resolve, reject) => {
    terminal.discoverReaders({simulated: false}).then(discoverResult => {
        if (discoverResult.error) {
            reject(discoverResult.error);
        }
        else if (discoverResult.discoveredReaders.length === 0) {
            reject(new Error('No available readers.'));
        }
        else {
            resolve(discoverResult.discoveredReaders);
        }
    });
});

export default class StripeTerminal {
    static create() {
        const stripe = {};
        return new Promise((resolve, reject) => {
            createInstance().then(async instance => {
                stripe.instance = instance;
                stripe.readers = await discoverStripeReaders(instance).catch(error => reject(error));
                resolve(stripe);
            }).catch(error => {
                reject(error);
            });
        });
    }
}