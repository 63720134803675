import React from 'react';
import PropTypes from 'prop-types';

const FAIcon = props =>
    <span {...props}><i className={`fa fa-${props.icon}`}/>{props.text}</span>;

FAIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.string
};

export default FAIcon;