import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Rails from '@rails/ujs';
import {notify} from 'react-notify-toast';
import Spinner from '../utilities/Spinner';
import DialogCloseButton from '../dialog_buttons/DialogCloseButton';

export default class DocumentsDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {templates: [], loading: true};
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        Rails.ajax({
            url: '/templates.json',
            type: 'get',
            success: templates => {
                if (this._isMounted) {
                    this.setState({templates, loading: false});
                }
            },
            error: error => {
                if (this._isMounted) {
                    notify.show(error, 'error');
                    this.setState({templates: [], loading: false});
                }
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderTemplate = template =>
        <div className='tr' key={template.id} onClick={() => this.props.callback(template.id)}>
            <div className='grid-x grid-margin-x align-middle'>
                <div className='auto cell'>
                    <i className='fa fa-file-text-o'/>{template.title}
                </div>
                <div className='shrink cell show-for-medium'>
                    <a className='clear success button'>
                        <i className='fa fa-plus'/>Add Document
                    </a>
                </div>
            </div>
        </div>;

    render() {
        return (
            <div className='reveal' id='documents-dialog'>
                <h2>Add Job Document</h2>

                {this.state.loading ? <Spinner/> : <div className='curved table'>
                    {this.state.templates.map(this.renderTemplate)}
                </div>}

                <DialogCloseButton/>
            </div>
        );
    }
}

DocumentsDialog.propTypes = {
    callback: PropTypes.func.isRequired
};