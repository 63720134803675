import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Util from './Util';

export default class TextArea extends PureComponent {
    state = {value: this.props.value || ''};

    render() {
        const id = Util.uuid();
        const {value} = this.state;
        const {label, minLength, name, placeholder, required, rows} = this.props;
        return (
            <div>
                <label htmlFor={id}>{label}</label>
                <textarea className='margin-0' id={id} minLength={minLength} name={name}
                    onChange={e => this.setState({value: e.target.value})}
                    placeholder={placeholder} required={required} rows={rows} value={value}/>
                {value.length < minLength ? <p className='muted'>
                    {minLength - value.length} more to go...
                </p> : <p className='muted'>
                    {value.length} characters
                </p>}
            </div>
        );
    }
}

TextArea.defaultProps = {
    required: false,
    rows: 3
};

TextArea.propTypes = {
    label: PropTypes.string,
    minLength: PropTypes.number,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.number,
    value: PropTypes.string
};