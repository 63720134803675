import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Address from '../Address';
import JobUrgency from './JobUrgency';
import AssignJobButton from '../dialog_buttons/AssignJobButton';

const JobsTableHeader = () =>
    <div className='curved primary thead'>
        <div className='grid-x grid-margin-x align-middle'>
            <div className='medium-1 cell text-center'>
                <small>Assigned</small>
            </div>
            <div className='medium-3 cell'>
                <small>Customer</small>
            </div>
            <div className='medium-3 cell'>
                <small>Address</small>
            </div>
            <div className='auto cell text-center'>
                <small>Urgency</small>
            </div>
            <div className='auto cell text-center'>
                <small>Status</small>
            </div>
            <div className='auto cell text-center'>
                <small>Date</small>
            </div>
        </div>
    </div>;

/* global Turbolinks:true */
const JobTableRow = ({editable, job}) =>
    <div className={`tr ${job.urgency ? job.urgency.toLowerCase() : ''}`} onClick={() =>
        Turbolinks.visit(`/jobs/${job.id}`)}>
        <div className='grid-x grid-margin-x align-middle align-justify'>
            <div className='small-4 medium-1 cell text-center small-order-6 medium-order-1'
                onClick={e => e.stopPropagation()}>
                <AssignJobButton editable={editable} job={job}/>
            </div>
            <div className='small-6 medium-3 cell small-order-1 medium-order-2 text-truncate'>
                <div className='text-truncate'><b>{job.customer.companyName || job.customer.fullName}</b></div>
                <small>{job.services.join(', ')}</small>
            </div>
            <div className='small-6 medium-3 cell small-order-2'>
                <Address address={job.address}/>
            </div>
            <div className='small-4 medium-auto cell text-center small-order-3 show-for-medium'>
                {job.urgency && <JobUrgency urgency={job.urgency}/>}
            </div>
            <div className='small-4 medium-auto cell text-center small-order-5'>
                <div className='text-truncate'><b><span className='info label'>{job.status}</span></b></div>
            </div>
            <div className='small-4 medium-auto cell medium-text-center small-order-4 medium-order-6'>
                <div className='text-truncate'>{moment(job.createdAt).format('D MMM YYYY')}</div>
            </div>
        </div>
    </div>;

JobTableRow.propTypes = {
    editable: PropTypes.bool.isRequired,
    job: PropTypes.shape({
        address: PropTypes.object.isRequired,
        customer: PropTypes.shape({
            companyName: PropTypes.string,
            fullName: PropTypes.string.isRequired
        }),
        id: PropTypes.number.isRequired,
        createdAt: PropTypes.string.isRequired,
        services: PropTypes.arrayOf(PropTypes.string).isRequired,
        status: PropTypes.string.isRequired,
        urgency: PropTypes.string
    }).isRequired
};

const JobsTable = ({editable, jobs}) =>
    <div className='card'>
        <div className='curved table'>
            {jobs.length > 0 && <JobsTableHeader/>}
            {jobs.map(job => <JobTableRow editable={editable} job={job} key={job.id}/>)}
        </div>
    </div>;

JobsTable.propTypes = {
    editable: PropTypes.bool.isRequired,
    jobs: PropTypes.array.isRequired
};

export default JobsTable;