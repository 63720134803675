import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class SelectWithOther extends PureComponent {
    constructor(props) {
        super(props);

        if (props.value && !props.items.find(item => {
            if (typeof item === 'object') {
                return item.key.toString() === props.value.toString();
            }

            return item.toString() === props.value.toString();
        })) {
            this.state = {otherSelected: true, otherValue: props.value};
        }
        else {
            this.state = {otherSelected: false};
        }
    }

    renderOptions = () => this.props.items.map(item => {
        if (typeof item === 'object') {
            return <option key={item.key} value={item.key}>{item.value}</option>;
        }

        return <option key={item} value={item}>{item}</option>;
    });

    handleSelect = e => {
        if (e.target.value === 'Other') {
            this.setState({otherSelected: true});
        }
        else {
            this.setState({otherSelected: false});
        }
    };

    getSelectedValue = () => {
        if (this.state.otherSelected) {
            return 'Other';
        }

        return this.props.value;
    };

    render() {
        return (
            <div className='grid-x grid-margin-x'>
                <div className='auto cell'>
                    <div className={this.props.errors && this.props.errors.length
                        ? 'field_with_errors' : ''}>
                        <select defaultValue={this.getSelectedValue()}
                            name={this.props.name} onChange={this.handleSelect}>
                            <option/>
                            {this.renderOptions()}
                            <option value='Other'>Other</option>
                        </select>
                    </div>
                </div>
                {this.state.otherSelected && <div className='auto cell'>
                    <input autoFocus={!this.state.otherValue} defaultValue={this.state.otherValue}
                        name={this.props.name} placeholder='Please indicate' type='text'/>
                </div>}
            </div>
        );
    }
}

SelectWithOther.propTypes = {
    errors: PropTypes.array,
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string
};